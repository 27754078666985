// React inbuild state hook
import { useState } from "react";

// Personnal global context (here to get the theme)
import useGlobalContext from "../../Hooks/useGlobalContext";

// Layout component
import CardLayout from "../Shared/Layout/CardLayout";

// Simple Component
import Inputs from "../Shared/InputSelect/Inputs"
import Btn from "../Shared/Basic/Btn";

// Info for the mail
import { recrutementEmail, contact } from "../../generalimports";

// Navigation bar at the tom
import LandingNav from "../LandingPage/LandingElements/LandingNav";

import useLanguage from "../../Hooks/useLanguage";

// import { generalTranslate } from "../../JSON/FR/GeneralArray";
// import { contactInputArray } from "../../JSON/FR/ContactArray";

//Component for formatting the email
const EmailButton = ({ data }) => {

    const { languageArray: generalTranslate } = useLanguage("GeneralArray", "generalTranslate")

    const { lastName, firstName, company, companyPhone, companyEmail, message } = data;

    const subject = `${generalTranslate?.emailFrom} ${firstName} ${lastName}`;
    const body = `${generalTranslate?.bodypart1} \n\n ${generalTranslate?.names} ${firstName} ${lastName} ${generalTranslate?.ofcompany} ${company}.\n ${generalTranslate?.mycontacts} ${companyPhone} ${companyEmail}. \n\n ${message}, \n\n ${generalTranslate?.bodypart4} ${firstName} ${lastName}`;

    const mailtoLink = `mailto:${recrutementEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    return (

        <a className={`col-12 center pb-1`}
            href={mailtoLink}>

            <Btn cssDiv={`display-f j-flex-end ai-center`}
                css={`font-ml text-white`}>

                {generalTranslate?.send}

            </Btn>

        </a>
    );
};

// Landing Page component

function LandingContact() {

    const { languageArray: contactInputArray } = useLanguage("ContactArray", "contactInputArray")

    // data from the fields
    const [data, setData] = useState([])
    // data to be send as a json to endpoint
    //console.log(data)

    // Theme Setter
    const { theme } = useGlobalContext()

    // JXS

    return (

        <>

            {contactInputArray &&

                <>
                    <LandingNav type={`not-principal`} title={`Contact`} colorTitle={`text-white`} />

                    <div className={`col-12 bg-primary-color-6`}>
                        <img src={contact} style={{ width: "100%" }} alt={`stuffy stuff`} />
                    </div>

                    <CardLayout css={`row col-12 j-center`}
                        bg={`bg-primary-color-6`}
                        p=""
                        style={{ minHeight: "56vh" }}>

                        <CardLayout css={`center row col-12 `} p="">

                            <form className={`col-12 center row`}>

                                <div className={`col-6-xxl col-6-xl col-12-lg col-12-md col-12-sm col-12-xs col center`}>

                                    <Inputs pClassName={`input-studiel-wrapper center t-center`}
                                        handleChange={(e) => setData(data => ({ ...data, [e.target.name]: e.target.value }))}
                                        entries={contactInputArray} />

                                </div>

                                <div className={`col-6-xxl col-6-xl col-12-lg col-12-md col-12-sm col-12-xs center col`}>

                                    <p className={`pos-r t-center`}>

                                        <span className={`center mb-2 label-textaera t-center ${theme}`}>Message</span>

                                        <div className={`col-12 center`}>
                                            <textarea
                                                className={`textaera-studiel center br-lg mb-2 ${theme}`}
                                                name={`message`}
                                                onChange={(e) => setData(data => ({ ...data, [e.target.name]: e.target.value }))}
                                            />
                                        </div>

                                    </p>

                                    <EmailButton data={data} />

                                </div>

                            </form>

                        </CardLayout>

                    </CardLayout>

                </>

            }

        </>
    );
}

export default LandingContact;