// Simple Components
import Title from "../Basic/Title";
import TextGroup from "../Basic/TextGroup";

// Layout Components
import CardLayout from "./CardLayout";

// Specific components the display of the  the page
// This is what displays all the single pages on the website.
// Specifics pages for the "JOBS" but also for the "GROUP"
import TextOnImage from "../../Groupe/GroupeElements/TextOnImage";
import CardWithNumber from "../../Groupe/GroupeElements/CardWithNumber";
import TextAndImage from "../../Groupe/GroupeElements/TextAndImage";
import ColorCards from "../../Groupe/GroupeElements/ColorCards";
import ColorTextImg from "../../Groupe/GroupeElements/ColortextImg";
import ValueCards from "../../Groupe/GroupeElements/ValueCards";
import SSERP from "../../Groupe/GroupeElements/SSERP";

import { ListRegular } from "../../Groupe/GroupeElements/ColortextImg";

// import useGlobalContext from "../../../Hooks/useGlobalContext";

// import serpimg from "../../../assets/demarche-sserp-2021-2023.svg"
import Lead from "../../Groupe/GroupeElements/Lead";
import Expertises from "../../Groupe/GroupeElements/Expertises";
import SystemManagement from "../../Groupe/GroupeElements/SystemManagement";

import { Interweave } from "interweave";
import Certifications from "../../Groupe/GroupeElements/Certifications";
import TextAndVid from "../../Groupe/GroupeElements/TextAndVid";
import Btn from "../Basic/Btn";

import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function Multiple({
    datas,
    color,
    col = "col-2",
}) {

    // const { theme } = useGlobalContext()

    const location = useLocation()

    useEffect(() => {
        if (location.hash) {
            const el = document.querySelector(location.hash)
            const top = window.scrollY + el.getBoundingClientRect().top
            window.scrollTo({ top, behavior: "smooth" })
        }
    }, [location])

    // JSX
    return (

        <CardLayout css={`display-f fd-c col-12 center`} p="" bg="">

            {!datas.text ? null :

                // headless text in the first array. (I think its unused for now)
                // Its globally unused. It was when I had text before in cardswithnumber but now its unused

                <TextGroup css={`m-2 col-10 mt-3 prewrap ${datas.addTextCss ? datas.addTextCss : ""}`}>

                    {datas.text}

                </TextGroup>

            }

            {!datas.points ? null :

                <div className={`col-12 cardwithnumbers-display margin-multiple`}>

                    {datas
                        ?.points
                        ?.map((row, rowIndex) => (

                            // IN "PAGE METIERS"

                            <CardWithNumber
                                key={rowIndex}
                                data={row}
                                index={rowIndex}
                                rowIndex={rowIndex}
                                col={col}
                                color={color} />

                        ))}

                </div>

            }

            {datas.body &&

                datas.body.map((text, index) => (

                    <div key={index} className={`center col col-12 pb-2`} id={text.id ? text.id : ""}>

                        {!text.title ? null :

                            // Principal text in body. (ei :first text && MANAGEMENT DES SYSTÈMES )
                            <div className={`display-flex-title-responsive center`}>

                                {!text.titlefirstpic ? null :

                                    <img src={text.titlefirstpic}
                                        alt={text.titlefirstpicalt}
                                        className={`responsive-img-title max-width-150px responsive-img-title-padding-right`}
                                    />

                                }

                                <Title css={`font-title-group fw-br prewrap t-center ${text.addCss ? text.addCss : ""}`}
                                    El={`h2`}>

                                    <Interweave content={text.title} />

                                </Title>

                                {!text.titlesecondpic ? null :


                                    <img src={text.titlesecondpic}
                                        alt={text.titlesecondpicalt}
                                        className={`responsive-img-title max-width-150px responsive-img-title-padding-left`}
                                    />

                                }

                            </div>

                        }

                        {!text.text ? null :

                            // Simple plain text like "L'activité d'assistance technique"

                            <div className={`margin-text-groupe prewarp ${text.textCss ? text.textCss : ""}`}>

                                <TextGroup>

                                    <Interweave content={text.text} />

                                </TextGroup>

                            </div>

                        }


                        {!text.subtitleh3 ? null :

                            // Principal Data

                            <Title css={`pl-5 mt-3 pt-2 font-ml fw-b prewrap col-10 j-flex-start t-start ${text.addCssSubh3 ? text.addCssSubh3 : ""}`}
                                El={`h3`}>

                                {text.subtitleh3}

                            </Title>

                        }

                        {!text.sserp ? null :

                            <SSERP array={text.sserp} />

                        }

                        {!text.colorTextImg ? null :

                            <ColorTextImg array={text.colorTextImg}
                                color={color} />

                        }

                        {!text.listing ? null :

                            // ONLY IN IT

                            <div className={`row col-12 j-space-between`}>

                                <div className={`col-6-xxl col-6-xl col-6-lg col-12-md col-12-sm col-12-xs center ${text.listingCss ? text.listingCss : ""}`}>

                                    <ListRegular text={text} numLi={800} white={false} />

                                </div>

                                <div className={`col-6-xxl col-6-xl col-6-lg col-12-md col-12-sm col-12-xs center p-2`}>

                                    <img className={`responsive-image`}
                                        src={text.listingpic}
                                        alt={`for now informatique IT studiel related`} />

                                </div>

                            </div>

                        }

                        {!text.leadtitle ? null :

                            <Lead title={text.leadtitle}
                                array={text.leads} />

                        }

                        {!text.expertiseArray ? null :

                            <Expertises array={text.expertiseArray} button={text.expertiseNavTo} />
                        }

                        {!text.systemManagement ? null :

                            <SystemManagement array={text.systemManagement} />

                        }

                        {!text.pics ? null :

                            // Logos stuff

                            <Certifications text={text} />

                        }

                        {!text.pic ? null :

                            <div className={`col-12 center`}>

                                <img src={text.pic} className={`responsive-image ${text.picCss ? text.picCss : ""}`}
                                    alt={text.alt ? text.alt : "Studiel groupe"} />

                            </div>
                        }

                        {!text.subtitleh4 ? null :

                            // H4 Title like System - Informatique and so on

                            <Title El={`h4`}
                                css={`mb-2 mt-2 font-lg ${text.addCss}`}>

                                {text.subtitleh4}

                            </Title>

                        }

                        {!(text.textLeft || text.textRight) ? null :

                            // Text + Pic on left or right stuff

                            <TextAndImage text={text} />
                        }

                        {!text.informations ? null :

                            // PAGE NOS REAALISATIONS A 95 %

                            <TextAndVid text={text} />

                        }

                        {!(text.imgTextTitle || text.imgText) ? null :

                            // NOTRE POLITIQUE QHSSE &&  
                            // STUDIEL EST ENGAGé && 
                            // STUDIEL S'ENGAGE

                            <TextOnImage text={text} />

                        }

                        {!text.colorCards ? null :

                            <ColorCards text={text.colorCards} />

                        }


                        {!text.valueCards ? null :

                            <ValueCards text={text.valueCards} />

                        }

                        {!text.button ? null :

                            <Btn handleClick={() => window.open(`${text.navLink}`)}

                                css={`${text.buttonCss ? text.buttonCss : ""}`}>

                                {text.button}
                            </Btn>
                        }

                    </div>

                ))

            }

        </CardLayout>
    );
}

export default Multiple;
