import { createPortal } from 'react-dom';

function ModalLayout({
  isOpen,
  children,
  handle,
  css = "",
}) {

  // JSX

  if (!isOpen) { return null }

  return (

    <>

      {createPortal(

        <div className={`modal-schrunk z-1000`} onClick={handle}>

          <div onClick={(e) => e.stopPropagation()}
            className={`modal-inner-shrunk center ${css} br-lg col-12`}>

            {children}

          </div>

        </div>, document.body

      )}

    </>
  )
}


export default ModalLayout;
