// React inbuid hook
import { useState, useRef, useEffect } from "react";

// Personal Hook for global data share and stuff
import useGlobalContext from "../../../Hooks/useGlobalContext"

// layout component
import ModalLayout from "../../Shared/Layout/ModalLayout";

// Simple comoponent
import Text from "../../Shared/Basic/Text";
import Btn from "../../Shared/Basic/Btn";

import useLanguage from "../../../Hooks/useLanguage";
// import { learnMore } from "../../../JSON/FR/LandingArray";

// Video
import videoStudielModal from "../../../assets/Videos/video-studiel-modal.mp4"

//fallback pic for the video
import { fallebackStudielFirst } from "../../../generalimports";

function LandingActualityOnCarrousel() {

    const { languageArray: learnMore } = useLanguage("LandingArray", "learnMore")

    // Where the modal is opened
    const [modal, setModal] = useState(false)
    const [color, setColor] = useState(false)

    // variable to change the theme
    const { theme } = useGlobalContext()

    // Check if the video is loaded before playing it. (so it doenst shutter)
    const videoRefModal = useRef(null);
    useEffect(() => {
        if (modal) {

            const videoElement = videoRefModal.current;

            videoElement.oncanplaythrough = () => {
                videoElement.play();
            };

            if (window.innerWidth < window.innerHeight) {
                videoElement.style.transform = "rotate(90deg)"
            } else {
                videoRefModal.current.style.transform = "rotate(0deg)";
            }

        }

    }, [modal]);


    return (

        <>

            <div className={`col-12`}>

                <div className={`display-f col-12 j-flex-end`}>

                    <aside className={`actuality-card center pt-2 pb-2 pl-4 pr-4 br-xxl
                    ${theme === "dark" ? "bg-primary-color-2-light-3" : (color ? "bg-primary-color-4-light-1" : "bg-primary-color-4")} pointer`}
                        onClick={() => setModal(c => !c)}
                        onMouseEnter={() => setColor(c => !c)}
                        onMouseLeave={() => setColor(c => !c)}
                    >

                        <Text textLight={`text-white font-discover`} css={`fw-b`}>

                            <span className={` center ${color ? "tdec-u" : ""}`}>

                                {learnMore?.text2}

                                {learnMore?.icon ?

                                    <learnMore.icon style={{ marginLeft: "30px", fontSize: "5vw" }} />
                                    :
                                    null
                                }

                            </span>


                        </Text>

                    </aside>

                </div>

            </div>

            <ModalLayout isOpen={modal} handle={() => setModal(c => !c)}>

                <video ref={videoRefModal}
                    onClick={() => setModal(c => !c)}
                    className={`p-2`}
                    style={{ maxHeight: "100vh", maxWidth: "95vh" }}
                    poster={fallebackStudielFirst}
                    muted
                    loop>

                    <source src={videoStudielModal} autoPlay muted loop type="video/mp4" />

                </video>

                <Btn cssDiv={`display-f j-flex-end ai-flex-end p-3 col-12`} handleClick={() => setModal(c => !c)}>Fermer</Btn>

            </ModalLayout >

        </>
    );
}

export default LandingActualityOnCarrousel;