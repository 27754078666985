// Simple Component
import Text from "./Basic/Text";

// Personal Context hook to pass data
import useGlobalContext from "../../Hooks/useGlobalContext";

// Component that show all the social medias
import SocialMedia from "./All/SocialMedias";

import useLanguage from "../../Hooks/useLanguage";
// import { generalTranslate } from "../../JSON/FR/GeneralArray";

function Footer() {

    const { theme } = useGlobalContext()

    const { languageArray: generalTranslate } = useLanguage("GeneralArray", "generalTranslate")

    return (

        <footer style={{ minHeight: "12vh" }} className={`col-12 display-f footer-behaviour j-space-between ai-center pt-3 ${theme === "dark" ? "bg-grey-dark-5" : "bg-grey"}`}>

            <div className={`col-3-xxl col-3-xl col-12-lg col-12-md col-12-sm col-12-xs j-flex-start footer-margin  col`}>

                {/* <div className={`pointer center`} onClick={() => setTheme(theme === "white" ? "dark" : "white")}>
                    {theme === "dark" ? <DarkModeIcon style={{ fontSize: "20px" }} /> : <LightModeIcon style={{ fontSize: "20px" }} />}
                </div> */}


                <a className={`mt-2 bt-3-grey-3 text-hover-primary-color-4 pointer pl-1 tdec-u`}
                    href="https://vu.fr/uasG" target='_blank' rel="noreferrer">
                    {generalTranslate?.plaquette}
                </a>


                <a className={`bt-3-grey-3 text-hover-primary-color-4 pointer pl-1 tdec-u`}
                    href="https://vu.fr/KDSzk" target='_blank' rel="noreferrer">
                    {generalTranslate?.technique}
                </a>


            </div>

            <div className={`col-6-xxl col-6-xl col-12-lg col-12-md col-12-sm col-12-xs center col footer-margin`}>

                <Text css={`t-center font-lg fw-b pb-2`}>{generalTranslate?.follow}</Text>

                <SocialMedia />

            </div>

            <div className={`col col-3-xxl col-3-xl col-12-lg col-12-md col-12-sm col-12-xs center footer-margin`}>

                <div>

                    <Text css={`mt-2 bt-3-grey-3 text-hover-primary-color-4 pointer`}
                        handle={() => window.open('/mentions-legales')}>

                        {generalTranslate?.rgpd}

                    </Text>


                </div>

            </div>

        </footer>

    );
}

export default Footer;