import Title from "../../Shared/Basic/Title";
import Text from "../../Shared/Basic/Text";
import TextGroup from "../../Shared/Basic/TextGroup";

import useObserver from "../../../Hooks/useObserver";

const ExpertiseTile = ({
    elem,
    array,
    index,
    button
}) => {

    const { containerRef, isVisible } = useObserver(0.5, true)

    return (

        <div key={index}
            className={`${elem?.col} col ${(index > 1 && index !== array.length - 1) && "expertise-padding-margin-box"}`}>

            <div
                key={index}
                style={{ backgroundImage: `url(${elem?.bg})` }}
                className={`col-12 col center pos-r expertise-background ${elem?.bgCss}`}
            >

                <div className={`col-12 display-f expertise-title-display expertise-overflow`}>

                    <Title El={`h3`} css={`font-sub-title-group text-white pb-4 pt-2 ${isVisible ? "slide-left" : ""}`}
                        ref={containerRef}>

                        {elem?.subtitle}

                    </Title>

                </div>

                <div className={`center col expertise-overflow`}>

                    <TextGroup
                        ref={containerRef}
                        css={`p-3 ${index !== array.length - 1 ? "" : ""} t-justify text-white ${isVisible ? "slide-right" : ""}`}>

                        {elem?.text}

                    </TextGroup>

                    {!elem.navto ? null :

                        <Text css={`tdec-u pointer text-white col-12 j-flex-start pointer mt-1 mb-1 pl-3`}
                            handle={() => window.open(elem?.navto)}>

                            {button}

                        </Text>
                    }

                </div>



                {!elem.pic ? null :

                    <img src={elem.pic} alt={`Studiel other pics expertises`} className={`circle-image-small ${elem.borderColor}`}
                        style={{ position: "absolute" }}
                    />

                }

            </div>
        </div>
    )
}

function Expertises({
    title,
    array,
    button,
}) {

    return (

        <>

            {!title ? null :
                <Title css={`mb-5 pb-3 font-xl fw-b prewrap`}
                    El={`h2`}>

                    {title}

                </Title>
            }

            <div className={`col-12 center expertise-wrapper col j-space-between`}>

                <div className={`col-12 row j-space-between expertise-gap-2`}>

                    {array
                        ?.map((elem, index) => (

                            <ExpertiseTile key={index} elem={elem} array={array} index={index} button={button} />

                        ))}

                </div>


            </div>
        </>
    );
}

export default Expertises;