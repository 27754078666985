import { useRef, useState, useEffect, useCallback } from "react";

function useObserver(visibilityFraction = 0.2, bool = false) {

    // state that changes if the div from above is in the viewport
    const [isVisible, setIsVisible] = useState(false)

    // callback function inside a useCallback (now thats fun ?) to check if the entry is "isIntersecting" meaning it's in the viewport
    const callback = useCallback((entries) => {
        const [entry] = entries
        if (!bool || (bool && !isVisible)) {
            setIsVisible(entry.isIntersecting)
            if (entry.isIntersecting) {
                entry.target.classList.remove('hide-initially');
            }
        }
    }, [bool, isVisible])
    // gets the right stuff in the ReactDom, here the divs where the stuff is in .. (yhea right ?)
    const containerRef = useRef()

    useEffect(() => {
        // We make a new pointer to observerRefValue so we can properly clean the useeffect when he's do
        let observerRefValue;
        // we had to get the callback function out to dismiss some issues of rerendering. 
        const observer = new IntersectionObserver(callback, {
            threshold: visibilityFraction // 50% of the target is visible
        });
        // thats how we observer the thing if we see the thing. (a Div here)
        if (containerRef.current) {
            observer.observe(containerRef.current)  // we're observing this one
            observerRefValue = containerRef.current // save ref value
        }
        // cleanup the useeffect
        return () => {
            if (observerRefValue) observer.unobserve(observerRefValue) // clean saved value
        }
    }, [callback, visibilityFraction])

    return { containerRef, isVisible }

}

export default useObserver