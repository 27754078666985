import React, { useState, useEffect } from "react";
import Title from "../../Shared/Basic/Title";
import Btn from "../../Shared/Basic/Btn";

import { managementSyst } from "../../../generalimports";
import ModalLayout from "../../Shared/Layout/ModalLayout";

import useLanguage from "../../../Hooks/useLanguage";

// import { generalTranslate } from "../../../JSON/EN/GeneralArray";

/// TODO : virer le state d'origine x)
const Points = ({
    point,
    windowWidth,
}) => {

    const [modal, setModal] = useState(false)

    if (!point) return null

    return (
        <>

            <div className={`p-1 pos-a z-5 bg-black br-lg font-sm`}
                style={{ left: `${windowWidth > 1200 ? "87%" : "60%"}`, top: `${windowWidth > 1200 ? `${point.positionTop}%` : "95%"}` }}
                onMouseEnter={() => setModal(c => !c)}
                onMouseLeave={() => setModal(c => !c)}
            >

                <p className={`text-white`}>{point.text}</p>

                {modal ?

                    <div className={`p-1 pos-a z-5 bg-black br-lg font-sm`}
                        style={{ left: "0%", top: `120%`, width: "max-content" }}>

                        <p className={`text-white`}>{point.hightlight}</p>

                    </div>

                    :

                    null
                }

            </div>

        </>
    )
}

const Dots = ({
    index,
    dots,
    windowWidth,
}) => {

    return (

        <span className={`dotted-border-black pos-a display-b col-8-xxl col-8-xl col-10-lg col-10-md col-12-sm col-12-xs system-management-grey-bars`}>

            {dots?.map((dot, i) => (

                <React.Fragment key={i}>

                    <span className={`dotted`}
                        style={{ left: "0%", top: `${dot.positionTop}%` }} />

                    <span className={`pos-a prewrap syst-dot-text ${index % 2 === 0 ? "" : "text-white"}`}
                        style={{ left: `${windowWidth < 1200 ? (dot.postionLeft ? `${dot.postionLeft}%` : `${dot.positionTop + 1}%`) : "15px"}`, top: `${windowWidth > 1200 ? `${dot.positionTop}%` : "30%"}` }}>

                        {dot?.text}

                    </span>

                </React.Fragment>

            ))
            }

        </span >

    )

}

function SystemManagement({ array }) {

    // < 1440
    // Ok alors ce truc commence à sauter au niveau des titres
    // certains textes dans les zones grises commencent aussi à s'entrochoquer vers ces eaux là

    // < 1200 
    // les bases grises et oranges deviennent vriament trop petites

    // < 1024 
    // C'est carrément illisible

    const { languageArray: generalTranslate } = useLanguage("GeneralArray", "generalTranslate")

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [active, setActive] = useState({ active: "desactivate", index: 0 })

    const [systManagement, setSystManagement] = useState(false)

    return (
        <>
            <div className={`col-12 row system-management-nowrap center syst-management-gap-1 pl-2 pr-2`}>

                {array
                    ?.map((elem, index) => (

                        <div className={`col-2`} key={index}>

                            <div className={`pl-050 pos-r pr-050 ${index % 2 === 0 ? "bg-primary-color-4" : "bg-primary-color-5"} br-lg display-f syst-management-title-div col-12 `}
                                style={{ height: "50px" }}
                                onClick={() =>
                                    setActive((active) => (active.active === "active" && active.index === index) ? { active: "desactivate", index: "" } : { active: "active", index: index })
                                }>

                                <Title El={`h4`} css={`prewrap syst-management-title center text-white`}>
                                    {elem?.title}
                                </Title>

                            </div>

                            <div className={`pl-050 pr-050 system-management-grey-bars ${index === active.index ? active.active : "desactivate"}
                                   ${index % 2 === 0 ? "bg-grey" : "bg-grey-dark-4"} br-lg center pos-r`}>

                                <Dots index={index}
                                    dots={elem?.dots}
                                    elem={elem}
                                    windowWidth={windowWidth}
                                    active={active} />

                                {elem
                                    ?.points
                                    ?.map((point, index) => (

                                        <Points key={index} point={point} windowWidth={windowWidth} />

                                    ))}

                            </div>

                        </div>

                    ))}


            </div>

            <div className={`system-management-tablet`}>

                <img src={managementSyst}
                    alt={`sytem managements Studiel`}
                    className={`responsive-image`} />

            </div>

            <div className={`systeme-management-mobile`}>

                <p onClick={() => setSystManagement(c => !c)}>{generalTranslate?.grow}</p>

            </div>

            <ModalLayout isOpen={systManagement}
                css={`syst systeme-management-modal`}
                handle={() => setSystManagement(c => !c)}>

                <img src={managementSyst}
                    alt={`sytem managements Studiel`}
                    className={`responsive-image-modal`} />

                <Btn handleClick={() => setSystManagement(c => !c)}>{generalTranslate?.close}</Btn>

            </ModalLayout >


        </>

    );
}

export default SystemManagement;