import { useState } from "react";

import CardLayout from "../../Shared/Layout/CardLayout";
import TextGroupe from "../../Shared/Basic/TextGroup"

function HiringCards({ data }) {

    // const { theme } = useGlobalContext()

    const [highlight, setHighlight] = useState(false)

    return (

        <>

            <a href={`/offer/${data.hireId}`} target="_blank" rel="noreferrer">

                <CardLayout p={`p-025`} css={`display-f fd-c cent col-12 pointer`}>

                    <div className={`display-f br-b-grey-3 j-space-between col-12 p-050 ${highlight ? "bg-grey" : "bg-white"} pt-1 pb-1`}
                        onMouseEnter={() => setHighlight(c => !c)}
                        onMouseLeave={() => setHighlight(c => !c)}>

                        <div className={`col `}>

                            <div>
                                <TextGroupe css={`tt-u fw-b ${highlight ? "text-primary-color-4" : ""}`}>

                                    {data?.hireTitle}

                                </TextGroupe>
                            </div>

                            <div>

                                <TextGroupe css={`tt-u text-grey-dark-3`}>

                                    {data?.hireDepartement} ({data?.hireNumDepartement})

                                </TextGroupe>

                            </div>

                        </div>

                        <div className={`center`}>

                            <TextGroupe css={`text-grey-dark-3`}> {data.hireRef} </TextGroupe>

                        </div>

                    </div>


                </CardLayout>

            </a>
        </>
    );
}

export default HiringCards;