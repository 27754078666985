import React from "react";

import useGlobalContext from "../../../Hooks/useGlobalContext";

const TextGroup = React.forwardRef(({
    children,
    css = "",
    textAlign = "t-justify",
}, ref) => {

    const { theme } = useGlobalContext()


    return (

        <p ref={ref} className={`${textAlign} prewrap  font-textgroup ${theme} ${css}`}>

            {children}

        </p>

    );
})

export default TextGroup;


