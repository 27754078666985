// React =)
import React, { useState, useEffect } from "react";

// Simple Components
import TextGroup from "../../Shared/Basic/TextGroup";
// import Title from "../../Shared/Basic/Title";

// Component for 
// "NOS ENGAGEMENTS SONT LES SUIVANTS"

function ColorCards({ text }) {

    const big = [0, 2, 4]
    const medium = [0, 3, 4]

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [currentArray, setCurrentArray] = useState(big);

    // 1 a > 1200
    // 1 a < 1200
    // 1 a < 960


    useEffect(() => {
        if (windowWidth > 1200) {
            setCurrentArray(big);
        }
        else if (windowWidth < 1200 && windowWidth > 960) {
            setCurrentArray(medium)
        }
        else if (windowWidth < 960) {
            setCurrentArray(big);
        }
        // eslint-disable-next-line
    }, [windowWidth]);

    // JSX

    return (

        <div className={`col-12 row center`}>

            {text.map((info, i) => (

                <React.Fragment key={i}>

                    {info.texts ?

                        <div className={`col-10 row`}>

                            {info.texts.map((elem, index) => (

                                <div key={index}
                                    className={`color-cards col-4-xxl col-4-xl col-6-lg col-12-md col-12-sm col-12-xs center ${currentArray.includes(index) ? "gradient-light-primary-color-4-5 text-white" : ""}`}
                                >

                                    {elem.text ?

                                        <TextGroup css={`padding-color-cards t-justify`}
                                            textLight={`${currentArray.includes(index) ? "text-white" : ""}`}>

                                            {elem.text}

                                        </TextGroup>
                                        :

                                        null
                                    }

                                    {elem.icon ?

                                        <elem.icon size={200}
                                            className={`text-primary-color-5`} />

                                        :

                                        null
                                    }

                                </div>
                            ))}



                        </div>

                        :

                        null

                    }

                </React.Fragment>
            ))
            }

        </div >


    );
}

export default ColorCards;