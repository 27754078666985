// Specific components
import StakesCards from "./StakesCards"

// Simple components
import Title from "../../Shared/Basic/Title"
import TextGroup from "../../Shared/Basic/TextGroup";

// Layout components
import CardLayout from "../../Shared/Layout/CardLayout"

import { lightbulbstakes } from "../../../generalimports";

// used in the groupe page for QHSSE and the horizontal image with text on it. -will always be centered-
// still needed : QHSSEcard to make it responsive.

function TextOnImage({ text }) {

    // JSX

    return (

        <CardLayout p="" css={`mt-2 mb-2 row background-image-general ${text.imgFromCss} col-12`} >

            {!text.imgTextTitle ? null :

                <CardLayout p="" css={`z-2 ${text.imgTextTitleCss} col-12`}>

                    <Title El={`h2`} css={`font-title-group fw-b text-white`}>{text.imgTextTitle}</Title>

                </CardLayout>

            }

            {!text.image ? null :

                <div className={`pos-a`} style={{ top: "15px", right: "15px" }}>

                    <img src={lightbulbstakes} style={{ width: "17vh" }}
                        alt={`ligth bulb stakes studiel groupe nos enjeux stakes`} />

                </div>

            }

            {!text.icon ? null :

                <div className={`col-1 center z-10`}>

                    <img src={text.icon}
                        style={{ width: "100%", maxWidth: "originalWidthpx" }}
                        alt="studiel logos s'engage démarches RSE" />
                </div>

            }

            {!text.imgText ? null :

                <CardLayout css={`z-2 ${(text.imgTextTitle || text.icon) ? "col-10" : "col-10"} ${text.imgTextCss}`} p="">

                    <TextGroup css={`text-white font-title-group fw-b`}
                        textAlign={`${text.center ? "t-center" : "t-justify"}`}>

                        {text.imgText}

                    </TextGroup>

                </CardLayout>

            }


            {!text.cards ? null :

                <CardLayout
                    css={`z-5 mb-3 qhsse-card-behaviour display-f col-12 row`} p="">

                    {text?.cards
                        ?.map((item, index) => (

                            <StakesCards item={item}
                                key={index} />

                        ))}

                </CardLayout>

            }


        </CardLayout>
    );
};

export default TextOnImage;
