import React, { useEffect, useState, useId } from "react";

import Title from "../../Shared/Basic/Title";
import TextGroup from "../../Shared/Basic/TextGroup";

import useObserver from "../../../Hooks/useObserver";

import useLanguage from "../../../Hooks/useLanguage";

// import { generalTranslate } from "../../../JSON/FR/GeneralArray";

const ListElemRegular = ({
    li,
    css,
    white,
    cssLiRegular = "",
}) => {

    return (

        <li className={`${cssLiRegular}`} style={{ textAlign: "unset" }}>

            <TextGroup css={`${white} ${css}`}>

                {li.text}

            </TextGroup>

        </li>
    )
}

const ListAccordeon = ({
    text,
    white = true,
    generalTranslate,
}) => {

    const [modal, setModal] = useState("")
    const id = useId()

    return (

        <ul className={`ul-style-1 col center text-white col-12 ${text.cssUl ? text.ccsUl : ""}`}>

            {text
                ?.listAccordeon
                ?.map((li, idx) => (

                    <div key={`${id}-${idx}`} className={`col-12 display-f j-center`}>

                        <div className={`display-f col-12 col`}>

                            <ListElemRegular
                                li={li}
                                text={text}
                                css={text.cssLi}
                                white={`${white ? "text-white" : "text-black"}`} />

                            <span className={`display-f col-7 ai-center j-flex-start`}
                                onClick={e => {
                                    e.preventDefault()
                                    if (modal === idx) {
                                        setModal("")
                                    } else {
                                        setModal(idx)
                                    }
                                }}>

                                {modal === idx ?

                                    <span className={`text-white display-b tdec-u`}>
                                        {generalTranslate?.readLess}
                                    </span>

                                    :

                                    <span className={`text-white  display-b tdec-u `}>
                                        {generalTranslate?.readMore}
                                    </span>

                                }

                            </span>

                            {modal === idx ?

                                <ul className={`ul-style-2 ${li.cssSubUl}`}>

                                    {li
                                        ?.subList
                                        ?.map((li, i) => (

                                            <ListElemRegular key={`${id}-${i}-${idx}`}
                                                li={li}
                                                text={text}
                                                white={`${white ? "text-white" : "text-black"}`} />

                                        ))}


                                </ul>


                                :

                                null

                            }

                        </div>


                    </div>

                ))}

        </ul>
    )
}


// DANS PRODUITS UNIQUEMENT
const ListAccordeonSimple = ({
    text,
    generalTranslate
}) => {

    const [hideText, setHideText] = useState(() => false);

    return (

        <div className={``}>

            <TextGroup css={`text-white fw-bold-mobile ${hideText ? "color-text-card-produit" : "pl-3"}`}>

                {hideText ?

                    <>
                        <p>{text.textHide}</p>

                        <p className={`pointer tdec-u`}
                            onClick={() => setHideText(c => !c)}>
                            {generalTranslate?.readLess}
                        </p>

                    </>

                    :

                    <p className={`pointer tdec-u`}
                        onClick={() => setHideText(c => !c)}>
                        {generalTranslate?.readMore}
                    </p>


                }

            </TextGroup>

        </div>
    )

}

export const ListRegular = ({
    text,
    numLi = 2,
    white = true,
    generalTranslate
}) => {

    const [isExpanded, setIsExpanded] = useState(false);

    const id = useId()

    const [isOverMaxNumLi, setisOverMaxNumLi] = useState()
    const [displayedList, setDisplayList] = useState()

    const handleClick = () => setIsExpanded(!isExpanded);

    useEffect(() => {
        if (text.listing) {
            setDisplayList(text.listing)
        } else {
            let maxNumLi = text?.list?.length > numLi;
            let displayed = isExpanded ? text.list : text?.list?.slice(0, numLi);
            setisOverMaxNumLi(maxNumLi)
            setDisplayList(displayed)
        }
    }, [isExpanded, text.list, text.listing, numLi])

    return (

        <div className={`color-text-card-scrollbox`}>

            <ul className={`ul-style-1 ${white ? "text-white" : "text-black"} ${text.cssUl ? text.cssUl : ""}`}>

                {displayedList
                    ?.map((li, idx) => (

                        <React.Fragment key={`${id}-${idx}`}>

                            <ListElemRegular li={li}
                                cssLiRegular={`${li.cssLiRegular ? li.cssLiRegular : ""}`}
                                css={`${li?.cssLi} fw-bold-mobile`}
                                white={`${white ? "text-white" : "text-black"}`} />

                            {li.subList ?

                                <ul className={`ul-style-2 ${li?.cssSubUl}`}>

                                    {li
                                        ?.subList
                                        ?.map((li, i) => (

                                            <ListElemRegular
                                                key={`${id}-${i}-${idx}`}
                                                li={li}
                                                css={`${li?.cssLi} fw-bold-mobile`}
                                                white={`${white ? "text-white" : "text-black"}`} />

                                        ))}

                                </ul>

                                :

                                null
                            }

                            {!li.src ? null :

                                <div className={`meca-image-render p-3 col-12`}>

                                    <img src={li.src}
                                        alt={`studiel developpers programs ${li?.subList?.map(elem => (elem.text))}`}
                                        style={{
                                            width: '30%',
                                            height: '100%',
                                            objectFit: 'contain'
                                        }} />

                                    {li.src2 &&
                                        <img src={li.src2}
                                            alt={`studiel developpers programs ${li?.subList?.map(elem => (elem.text))}`}
                                            style={{
                                                width: '30%',
                                                height: 'auto',
                                                objectFit: 'contain'
                                            }} />
                                    }

                                </div>
                            }

                        </React.Fragment>

                    ))}

            </ul>

            {isOverMaxNumLi &&

                <>

                    <span onClick={handleClick}
                        className="display-b">

                        {isExpanded ?

                            <span className={`text-white fw-bold-mobile pt-2 display-b tdec-u`}>

                                {generalTranslate?.readLess}

                            </span>

                            :

                            <span className={`text-white fw-bold-mobile pt-2 display-b tdec-u`}>

                                {generalTranslate?.readMore}

                            </span>

                        }

                    </span>


                </>
            }

        </div>
    )
}

const ColorTextTile = ({
    elem,
    index,
    color,
    css = ""
}) => {

    // Generate a unique ID and load the translation array
    const id = useId();

    const { languageArray: generalTranslate } = useLanguage("GeneralArray", "generalTranslate");

    // Building the class names for the main container div
    const baseClass = `${Array.isArray(elem.arrayImgRight) || Array.isArray(elem.arrayImgLeft) ? "" : `${elem.widthText ? `${elem.widthText}` : "color-image-overlay-width"}`}`;

    const conditionalClass = `${elem.arrayImgRight || elem.arrayImgLeft ? "col" : ""}`;

    const colorClass = elem.dark ?
        `bg-grey-dark-7 ${elem.colorImgRight ? `fd-rev ${elem.lefter ? "right-colortextimg-lefter" : "right-colortextimg"}` : (Array.isArray(elem.arrayImgRight) || Array.isArray(elem.arrayImgLeft)) ? (elem.colText ? elem.colText : "col-6") : "left-colortextimg"}`
        :
        `${index % 2 === 0 ? `bg-${color} ${elem.arrayImgRight || elem.arrayImgLeft ? elem.colText : "left-colortextimg"}` : `bg-${color}-dark-3 ${elem.arrayImgRight || elem.arrayImgLeft ? elem.colText : elem.lefter ? "right-colortextimg-lefter" : "right-colortextimg"}`}`;

    // Combine all classes
    const combinedClass = `${baseClass} ${conditionalClass} ${colorClass} ${css}`;

    return (

        <div className={combinedClass}>

            <div className={`color-text-card-scrollbox ${elem.addCss ? elem.addCss : ""}`}>

                {elem
                    ?.colorText
                    ?.map((text, subIndex) => (

                        <div key={`${id}-${subIndex}`}
                            className={`col ${(elem.arrayImgRight || elem.arrayImgLeft) ? `display-f j-space-between h-100` : "col-12 color-text-card-p"}`}>

                            {/* ICI FAUDRA PROBABLEMENT RAJOUTER UN J-SPACE-BETWEEN POUR LA MECA EN DESKOP POUR METTRE LES TRUCS EN FACE */}

                            {!text.title ? null :
                                <Title El="h3" css={`font-sub-title-group text-white pb-3 ${text.cssTitle}`}>
                                    {text.title}
                                </Title>
                            }

                            {!text.text ? null :
                                <TextGroup css={`text-white fw-bold-mobile color-text-card-p`}>
                                    {text.text}
                                </TextGroup>
                            }

                            {!text.textHide ? null :
                                <ListAccordeonSimple generalTranslate={generalTranslate} text={text} />
                            }

                            {!text.list ? null :
                                <ListRegular generalTranslate={generalTranslate} text={text} numLi={text.numLi} />
                            }

                            {!text.listAccordeon ? null :
                                <ListAccordeon generalTranslate={generalTranslate} text={text} />
                            }

                        </div>

                    ))}
            </div>

        </div>
    );
};

const ColorTextWrapper = ({
    elem,
    index,
    color,
    children
}) => {

    // Using a custom observer hook
    const { containerRef, isVisible } = useObserver(0.5, true);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Class logic for various divs
    // Global Container
    const containerClass = `col-10-xxl col-10-xl col-10-lg col-10-md col-12-sm col-12-xs center hide-initially row pos-r mb-4 color-wrapper-padding overflow-hidden
        ${index % 2 === 0 ? "j-flex-start color-wrapper-padding" : "j-flex-end"}
        ${elem.colorImgLeft ? "" : "fd-rev"}
        ${elem.zIndex ? elem.zIndex : ""}`;

    // Inner Divs
    const innerDivClass = `display-f col-12  
        ${(elem.colorImgLeft || elem.arrayImgLeft) ? "color-card-image-flex-start" : "color-card-image-flex-end"}
        ${Array.isArray(elem.arrayImgRight) ? "fd-rev" : ""}`;

    // Style logic for image
    const imgStyle = {
        width: windowWidth > 1280 ? elem.width ? `${elem.width}vh` : "55vh" : "100vw",
        height: "100%",
        objectFit: "cover",
        maxWidth: "100%",
        minHeight: "400px"
    };

    // The pics and animations
    const imgClass = `img-jobs ${isVisible && (index % 2 === 0 ? "shadow-slide-right" : "shadow-slide-left")}`;

    // The cards animations >= 1200 
    const cardClass = `color-text-tile-pos hidden ${isVisible && (index % 2 === 0 ? "slide-left" : "slide-right")}`

    // To get the SRCs
    const imgSrc = elem.colorImgLeft || elem.colorImgRight

    return (

        <div ref={containerRef} className={containerClass}>

            <div className={innerDivClass}>

                <div className={`pos-r row`}>

                    {/* Check if either colorImgLeft or colorImgRight exists */}

                    {imgSrc ?

                        <div className={`pos-r color-image-height`}>

                            {/* Image bg*/}
                            <img
                                src={imgSrc}
                                alt="studiel metiers"
                                className={imgClass}
                                style={imgStyle}
                            />

                            {/* ColorTextTile Components shown at 1200px */}
                            <ColorTextTile elem={elem}
                                index={index}
                                color={`${color}-trans`}
                                css={`color-image-overlay color-text-tile-overlay`} />

                            {/* ColorTextTile Components hidden at 1200px */}

                            <ColorTextTile
                                elem={elem}
                                index={index}
                                color={color}
                                css={cardClass}
                            />

                        </div>

                        : null

                    }

                </div>

                {/* Children */}
                {children}

            </div>

        </div>
    );
};


function ColorTextImg({
    array,
    color
}) {

    const id = useId()

    return (

        <div className={`col-12 center row`}>

            {array.map((elem, index) => (

                <React.Fragment key={`${id}-${index}`}>

                    <ColorTextWrapper elem={elem} index={index} color={color}>

                        {elem.arrayImgLeft || elem.arrayImgRight ?

                            <div className={`col-6 j-space-around ai-center meca-images-array`}>

                                {/* // TITRES DES IMAGES MECA UNIQUEMENT */}

                                <Title El={`h3`}
                                    css={`font-xl text-black m-3 p-3 ${elem.cssTitle}`}>

                                    {elem.arrayImgTitle}

                                </Title>


                                {(elem.arrayImgLeft || elem.arrayImgRight).map((img, i) => (

                                    // IMAGES DANS MECA UNIQUEMENT

                                    <div key={`-${i}-${index}-${id}`}
                                        style={{ height: "auto" }}
                                        className={`col-12 display-f row j-center p-3 mt-1 mb-1 bg-grey ${index % 2 === 0 ? "br-tl-xl br-bl-xl" : "br-tr-xl br-br-xl"}`}>

                                        {!img.src2 ? null :

                                            <img src={img.src2}
                                                alt={`studiel ${elem?.arrayImgTitle}`}
                                                className={`p-1`}
                                                style={{
                                                    width: '20%',
                                                    height: 'auto',
                                                    objectFit: 'contain'
                                                }} />
                                        }

                                        <img src={img.src}
                                            alt={`studiel ${elem?.arrayImgTitle}`}
                                            className={`p-1`}
                                            style={{
                                                width: img.width ? img.width : "30%",
                                                height: 'auto',
                                                objectFit: 'contain'
                                            }} />

                                    </div>

                                ))}

                            </div>

                            :

                            null

                        }

                        {(elem.arrayImgLeft || elem.arrayImgRight) ?

                            <ColorTextTile
                                elem={elem}
                                index={index}
                                color={`meca-jobs`} />

                            :

                            null
                        }

                    </ColorTextWrapper>

                </React.Fragment>

            ))}

        </div>

    );
}

export default ColorTextImg;

// Dynamicly created classes that don't stay after css purge. Don't touche please <3
// bg-primary-color-syst
// bg-primary-color-info
// bg-primary-color-info
// bg-primary-color-prod
// bg-primary-color-meca
// bg-primary-color-syst-dark-3
// bg-primary-color-info-dark-3
// bg-primary-color-info-dark-3
// bg-primary-color-prod-dark-3
// bg-primary-color-syst-trans
// bg-primary-color-info-trans
// bg-primary-color-info-trans
// bg-primary-color-prod-trans
// bg-primary-color-syst-trans-dark-3
// bg-primary-color-info-trans-dark-3
// bg-primary-color-info-trans-dark-3
// bg-primary-color-prod-trans-dark-3