// React inbuild hooks
import { useRef, useEffect } from "react";

// Video
import videoFirstPage from "../../../assets/Videos/video-studiel-first-page.mp4"

// Fallback img for the video
import { fallebackStudielFirst } from "../../../generalimports";

function LandingVideo() {

    // Check if the video is loaded before playing it. (so it doenst shutter)
    const videoRef = useRef(null);
    useEffect(() => {
        const videoElement = videoRef.current;
        videoElement.oncanplaythrough = () => {
            videoElement.play();
        };
    }, []);

    // JSX

    return (

        <>
            <video ref={videoRef}
                className={`card-video`}
                poster={fallebackStudielFirst}
                muted
                loop>

                <source src={videoFirstPage} autoPlay muted loop type="video/mp4" />

            </video>

        </>

    );
}

export default LandingVideo;