// React inbuild hooks
import { useEffect, useState } from "react";

// Navigation bar at the tom
import LandingNav from "../LandingPage/LandingElements/LandingNav";

// Clickable single hiring line
import HiringCards from "./HiringElements/HiringCards";

// Layout component
import CardLayout from "../Shared/Layout/CardLayout";

// Simple component
import Text from "../Shared/Basic/Text";
import Title from "../Shared/Basic/Title";
import Selector from "../Shared/InputSelect/Selector"
import Btn from "../Shared/Basic/Btn";

// Personnal Hook for fetching
import useFetch from "../../Hooks/useFetch"

// ICONS => Search && Clear Icon (obvioysly) + top icons array
import { Search01Icon } from "hugeicons-react";
import { Cancel01Icon } from "hugeicons-react";

// To get fuzzy ? packge to insert mistakes in text and still wokring
import Fuse from "fuse.js";

import { recrutementEmail } from "../../generalimports";

import useLanguage from "../../Hooks/useLanguage";
// import { generalTranslate } from "../../JSON/FR/GeneralArray";
// import { hireIcons } from "../../JSON/EN/HireArray";
import TextGroup from "../Shared/Basic/TextGroup";

// Matching Tables for the names
const activityLabelMapping = {
    achatappro: "Achat Approvisionnement",
    caoelectroniqueelectrique: "CAO Electronique Electrique",
    caomecanique: "CAO Mecanique",
    gestiondeproduction: "Gestion De Production",
    gestiondeprojet: "Gestion de projet",
    gestiondeprojets: "Gestion de projets",
    geniecivil: "Génie Civil",
    editiondelogiciels: "Edition de Logiciels",
    secteurpublic: "Secteur Public"
};

const EmailUnsolicitedApplication = () => {

    const { languageArray: generalTranslate } = useLanguage("GeneralArray", "generalTranslate")

    const subject = generalTranslate?.spontanous;
    const body = `${generalTranslate?.bodypart1} 
    
    ${generalTranslate?.bodypart2}

    ${generalTranslate?.bodypart3}

    ${generalTranslate?.bodypart4}  
    `

    const mailtoLink = `mailto:${recrutementEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    return (

        <a className={`col-12 center mb-1 mt-1`}
            href={mailtoLink}>

            <Btn cssDiv={`display-f j-flex-end text-white mt-2`}
                css={`font-ml tt-u p-150`}
                btnStyleLight={`btn-primary-color-4`}>

                {generalTranslate?.spontanous}

            </Btn>

        </a>
    );
};

function Hiring() {

    // FETCHING GROOVING AND MOOVING
    const { data: hiringData, pending, error } = useFetch("/JSON/HireList.json")

    // QUERY FOR THE 'MOT CLE FIELD //
    const [query, setQuery] = useState()
    const [fuseResult, setFuseResult] = useState([])

    const { languageArray: hireIcons } = useLanguage('HireArray', "hireIcons")
    const { languageArray: generalTranslate } = useLanguage("GeneralArray", "generalTranslate")

    useEffect(() => {
        document.title = `Groupe Studiel - Offre d'emplois`;
    }, [])

    useEffect(() => {

        if (hiringData.length > 0) {

            const fuseOptions = {
                keys: Object.keys(hiringData[0]),
                includeScore: true,
                threshold: 0.3,
            };

            const fuse = new Fuse(hiringData, fuseOptions);

            if (fuse && query) {
                setFuseResult(fuse.search(query).map((result) => result.item))
            }
        }

    }, [query, hiringData])

    // filderedData, non destructive by setting a new array from the fetch 
    const [filteredHiringData, setFilteredHiringData] = useState([])

    // To set the activity aeras list 
    const [activityAreas, setActivityAreas] = useState([]);

    // to get the selected activity aera (will filter with this)
    const [selectedActivity, setSelectedActivity] = useState("")

    useEffect(() => {
        if (hiringData.length > 0) {
            setFilteredHiringData(hiringData)
        }
    }, [hiringData])

    // Thingy to show number of results
    const [numberOfResultsToShow, setNumberOfResultsToShow] = useState(25);


    // Activity Aera 
    // Here the code is making the selector list for Expertise

    useEffect(() => {
        if (hiringData.length > 0) {
            const activityAreasList = hiringData.reduce((uniqueActivityAreas, activity) => {

                const validTitles = ["ingenieur", "technicien"];

                activity.hireActivityAreas?.forEach((area) => {
                    if (validTitles.includes(area)) {
                        const newLabel = activityLabelMapping[area] || area;
                        const foundActivityArea = uniqueActivityAreas.find(
                            (uniqueArea) =>
                                uniqueArea.label === newLabel &&
                                uniqueArea.value === area
                        );

                        if (!foundActivityArea) {
                            uniqueActivityAreas.push({
                                value: area,
                                label: newLabel,
                            });
                        }
                    }
                });

                validTitles.forEach((title) => {

                    if (activity.hireTitle?.toLowerCase().includes(title)) {

                        const newLabel = activityLabelMapping[title] || title;

                        const foundActivityArea = uniqueActivityAreas.find(
                            (uniqueArea) =>
                                uniqueArea.label === newLabel &&
                                uniqueArea.value === title
                        );

                        if (!foundActivityArea) {
                            uniqueActivityAreas.push({
                                value: title,
                                label: newLabel,
                            });
                        }
                    }
                });

                return uniqueActivityAreas;
            }, []);

            activityAreasList.sort((a, b) => a.label.localeCompare(b.label));
            setActivityAreas(activityAreasList);
        }
    }, [hiringData]);

    // EXPERTISE 
    // Here the code is making the selector list for Expertise
    const [expertise, setExpertise] = useState([])
    // select the expertise (will filterter with this)
    const [selectExpertise, setSelectExpertise] = useState("")

    useEffect(() => {

        if (hiringData.length > 0) {

            const expertiseList = hiringData.reduce((uniqueExpertise, expertise) => {

                if (expertise.hireExpertiseArea !== "-1") {

                    const newLabel = activityLabelMapping[expertise.hireExpertiseArea] || expertise.hireExpertiseArea

                    const foundExpertise = uniqueExpertise.find(
                        (uniqueExpertise) =>
                            uniqueExpertise.label === newLabel &&
                            uniqueExpertise.value === expertise.hireExpertiseArea
                    );

                    if (!foundExpertise) {
                        uniqueExpertise.push({
                            value: expertise.hireExpertiseArea,
                            label: newLabel,
                        })
                    }

                }

                return uniqueExpertise

            }, [])

            expertiseList.sort((a, b) => a.label.localeCompare(b.label));

            setExpertise(expertiseList)
        }

    }, [hiringData])

    // Geographical Zone
    // Here the code is making the selector list for Geographical Zone
    const [georgraphicalZone, setGeorgraphicalZone] = useState([])
    // Selected geographical zone (will filter with this)
    const [selectGeographicalZone, setSelectGeographicalZone] = useState("")

    useEffect(() => {

        if (hiringData.length > 0) {

            const newGeographicalZones = hiringData.reduce((uniqueGZ, gz) => {
                const foundGZ = uniqueGZ.find(
                    (uniqueGZ) =>
                        uniqueGZ.label === gz.hireGeographicalZone &&
                        uniqueGZ.value === gz.hireGeographicalZone
                );

                if (!foundGZ && (gz.hireGeographicalZone !== "France" && gz.hireGeographicalZone !== "Etranger")) {
                    uniqueGZ.push({
                        value: gz.hireGeographicalZone,
                        label: gz.hireGeographicalZone,
                    });

                }
                return uniqueGZ;
            }, []);

            newGeographicalZones.sort((a, b) => a.label.localeCompare(b.label));

            setGeorgraphicalZone(newGeographicalZones);
        }

    }, [hiringData]);

    // FILTER FUNCTION !!!
    useEffect(() => {

        if (fuseResult.length > 0 && query) {

            const filter = fuseResult
                .filter(firstFilter => {
                    return !selectGeographicalZone || selectGeographicalZone === firstFilter.hireGeographicalZone;
                })
                .filter(forthFilter => {
                    return !selectExpertise || selectExpertise === forthFilter.hireExpertiseArea;
                })
                .filter(fifthFilter => {
                    const validTitles = ["ingenieur", "technicien"];

                    const activityPresent = fifthFilter.hireActivityAreas?.some(activity => activity === selectedActivity);
                    const titlePresent = validTitles.some(() => fifthFilter.hireTitle?.toLowerCase().includes(selectedActivity));

                    return (
                        !selectedActivity || activityPresent || titlePresent
                    );
                })

            setFilteredHiringData(filter);

        } else if (hiringData.length > 0) {

            const filter = hiringData
                .filter(queryFilter => {

                    if (!query) return true;

                    for (let key in queryFilter) {
                        if (queryFilter.hasOwnProperty(key)) {
                            let keyValue = queryFilter[key]?.toString()?.toLowerCase();
                            if (keyValue?.includes(query.toLowerCase())) {
                                return true;
                            }
                        }
                    }
                    return false;
                })
                .filter(firstFilter => {
                    return !selectGeographicalZone || selectGeographicalZone === firstFilter.hireGeographicalZone;
                })
                .filter(forthFilter => {
                    return !selectExpertise || selectExpertise === forthFilter.hireExpertiseArea;
                })
                .filter(fifthFilter => {
                    const validTitles = ["ingenieur", "technicien"];

                    const activityPresent = fifthFilter.hireActivityAreas?.some(activity => activity === selectedActivity);
                    const titlePresent = validTitles.some(title => fifthFilter.hireTitle?.toLowerCase().includes(selectedActivity));

                    return (
                        !selectedActivity || activityPresent || titlePresent
                    );
                })


            setFilteredHiringData(filter);
        }
    }, [query, selectedActivity, selectGeographicalZone, selectExpertise, hiringData, fuseResult]);


    //  to change the placehodler depnending on the windowwidth
    const [placeholderText, setPlaceholderText] = useState(generalTranslate?.keyWord);
    //  to change the placehodler depnending on the windowwidth

    useEffect(() => {

        function updatePlaceholder() {
            if (window.innerWidth <= 780) {
                setPlaceholderText(generalTranslate?.keyWordShort);
            } else {
                setPlaceholderText(generalTranslate?.keyWord);
            }
        }

        updatePlaceholder();
        window.addEventListener('resize', updatePlaceholder);

        // return to clean up
        return () => {
            window.removeEventListener('resize', updatePlaceholder);
        };

    }, [generalTranslate])

    // JSX // JSX // JSX // 
    // JSX // JSX // JSX // 
    // JSX // JSX // JSX // 

    return (

        <>

            <LandingNav type={`not-principal`}
                title={generalTranslate?.title}
                colorTitle={`text-white`}
                bgLightMode={`bg-primary-color-4`} />


            <CardLayout css={`center mb-3 fd-c col-12`}>

                <div className={`display-f j-flex-start col-10`}>

                    <Title El={`h2`} css={`pt-2`}>{generalTranslate?.joinUs}</Title>

                </div>

                <CardLayout css={`mt-4 row center col-10-xxl col-12-xl col-12-lg col-12-md col-12-sm col-12-xs`}>

                    {hireIcons?.map((item, index) => (

                        <div key={index} className={`col-3-xxl col-3-xl col-6-lg col-6-md col-12-sm col-12-xs `}>

                            <div className={`center`}>

                                <img src={item?.icon}
                                    style={{
                                        maxHeight: "100%",
                                        maxWidth: "100%",
                                        height: '13vh',
                                        width: 'auto'
                                    }}
                                    alt={item.text} />

                            </div>

                            <Text cssDiv={`center`} css={`font-ml mt-2 pt-2 prewrap t-center`}>

                                {item.text}

                            </Text>

                        </div>

                    ))}

                </CardLayout>

                <CardLayout css={`mt-4 col center col-10-xxl col-12-xl col-12-lg col-12-md col-12-sm col-12-xs`}>

                    <CardLayout css={`col-10-xxl col-12-xl col-12-lg col-12-md col-12-sm col-12-xs display-f fd-c hire-selectors-input-general-padding center bg-primary-color-4`}>

                        <div className={`search-container col-12 center pt-2`}>

                            <div className={`pos-r center search-box col-10`}>

                                <label className={`search-icon-container`} htmlFor="inputQuery">
                                    <Search01Icon className={`text-primary-color-4 search-icon`} style={{ fontSize: "24px" }} />
                                </label>

                                <input
                                    id="inputQuery"
                                    type='text'
                                    value={query || ""}
                                    className={`input-studiel-search selector-studiel-padding`}
                                    placeholder={placeholderText}
                                    onChange={(e) => setQuery(e.target.value)}
                                />

                                <div className="clear-icon-container">
                                    <Cancel01Icon onClick={() => setQuery()} className="text-primary-color-4 clear-icon" />
                                </div>

                            </div>

                        </div>


                        <div className={`row col-12 mt-2 center`}>

                            <div className={`col-4-xxl col-4-xl col-12-lg col-12-md col-12-sm col-12-xs selector-studiel-padding`}>

                                {georgraphicalZone &&

                                    <Selector
                                        value={selectGeographicalZone}
                                        css={`col-12`}
                                        entries={georgraphicalZone}
                                        empty={generalTranslate?.localisation}
                                        addSelectByEvent={(e) => {
                                            setSelectGeographicalZone(e.target.value)

                                        }} />
                                }


                            </div>

                            <div className={`col-4-xxl col-4-xl col-6-lg col-6-md col-12-sm col-12-xs selector-studiel-padding`}>

                                <Selector
                                    value={selectedActivity}
                                    css={`col-12`}
                                    empty={generalTranslate?.post}
                                    entries={activityAreas}
                                    addSelectByEvent={(e) => setSelectedActivity(e.target.value)} />

                            </div>

                            <div className={`col-4-xxl col-4-xl col-6-lg col-6-md col-12-sm col-12-xs selector-studiel-padding`}>

                                <Selector
                                    value={selectExpertise}
                                    css={`col-12`}
                                    empty={generalTranslate?.expertise}
                                    entries={expertise}
                                    addSelectByEvent={e => setSelectExpertise(e.target.value)} />

                            </div>

                        </div>

                    </CardLayout>

                    <EmailUnsolicitedApplication />

                    {pending &&

                        <div style={{ minHeight: "40vh" }} className={`center`}>

                            <TextGroup>
                                {generalTranslate?.loading}
                            </TextGroup>

                        </div>

                    }

                    {error && <p>{error}</p>}

                    <CardLayout css={`col-10-xxl col-12-xl col-12-lg col-12-md col-12-sm col-12-xs col`} p="">

                        {!pending && filteredHiringData.length > 0 &&

                            <>
                                {filteredHiringData
                                    ?.slice(0, numberOfResultsToShow)
                                    ?.map((hiringInfo, index) => (

                                        <HiringCards
                                            data={hiringInfo}
                                            key={index}
                                            index={index}
                                        />

                                    ))}

                                {filteredHiringData.length > numberOfResultsToShow &&


                                    <div className={`row  j-space-between mt-3`}>

                                        <Btn cssDiv={`m-a`}
                                            handleClick={() => setNumberOfResultsToShow(n => n + 25)}>

                                            {generalTranslate?.seeMore}

                                        </Btn>

                                        <div className={`display-f j-flex-end fw-b o-50`}>

                                            {generalTranslate?.announces} : {numberOfResultsToShow}/{filteredHiringData?.length}

                                        </div>


                                    </div>
                                }

                            </>
                        }

                        {!pending && filteredHiringData.length === 0 &&

                            <CardLayout css={`center col`} style={{ minHeight: "40vh" }}>

                                <Text css={`t-center`}>{generalTranslate?.nooffers}</Text>

                                <Btn cssDiv={`m-3`}
                                    handleClick={() => {
                                        setQuery("")

                                        setSelectExpertise('')
                                        setSelectedActivity('')
                                        setSelectGeographicalZone('')

                                    }}>

                                    {generalTranslate?.tozero}

                                </Btn>

                            </CardLayout>
                        }

                    </CardLayout>

                </CardLayout>

            </CardLayout>

        </>
    );
}

export default Hiring;


////
/// OLDIES
////

/* retiré le 16/05/2023, j'ai juste commenté vu qu'ils peuvent encore changer d'avis
                                {regions &&

                                    <Selector
                                        value={selectRegion}
                                        css={`col-12`}
                                        entries={regions}
                                        empty={`Localisation`}
                                        addSelectByEvent={(e) => {
                                            setSelectRegion(e.target.value)
                                            setSelectDepartement('')
                                            setDepartements('')
                                        }} />
                                } 
                             

                                {departements &&
                                    <Selector
                                        value={selectDepartement}
                                        css={`col-12`}
                                        entries={departements}
                                        empty={`Localisation`}
                                        addSelectByEvent={(e) => setSelectDepartement(e.target.value)} />
                                }



 //  retiré le 16/05/2023, j'ai juste commenté vu qu'ils peuvent encore changer d'avis
                            //   setSelectRegion('')
//    setRegions()
//     setSelectDepartement('')
 //    setDepartements()
 //  setSelectRegion('')
// setRegions('')
//  setSelectDepartement('')
//   setDepartements('')
                                


                                                // Region
    // Here the code is making the selector list for Region
    // const [regions, setRegions] = useState()
    // // select the region (will filter with this)
    // const [selectRegion, setSelectRegion] = useState("")
    // useEffect(() => {
    //     if (hiringData.length > 0) {
    //         const newRegions = hiringData
    //             .filter(firstFilter => selectGeographicalZone === firstFilter.hireGeographicalZone)
    //             .reduce((uniqueRegion, region) => {
    //                 const foundRegion = uniqueRegion.find(
    //                     (uniqueRegion) =>
    //                         uniqueRegion.label === region.hireRegion &&
    //                         uniqueRegion.value === region.hireRegion
    //                 );
    //                 if (!foundRegion) {
    //                     uniqueRegion.push({
    //                         value: region.hireRegion,
    //                         label: region.hireRegion
    //                     })
    //                 }
    //                 return uniqueRegion;
    //             }, [])
    //         newRegions.sort((a, b) => a.label.localeCompare(b.label));
    //         if (newRegions.length > 0) {
    //             setRegions(newRegions)
    //         }
    //         else { setRegions() }
    //     }
    //     // eslint-disable-next-line
    // }, [selectGeographicalZone])


    //   retiré le 16/05/2023, j'ai juste commenté vu qu'ils peuvent encore changer d'avis
    // Departements
    // Here the code is making the selector list for Departements 
    // const [departements, setDepartements] = useState()
    // Select the departement (will filter with this)
    //  const [selectDepartement, setSelectDepartement] = useState("")
    // useEffect(() => {
    //     if (hiringData.length > 0) {
    //         const newDepartements = hiringData
    //             .filter(firstFilter => selectRegion === firstFilter.hireRegion)
    //             .reduce((uniqueDepartement, departement) => {
    //                 const foundDepartement = uniqueDepartement.find(
    //                     (uniqueDepartement) =>
    //                         uniqueDepartement.label === departement.hireNumDepartement &&
    //                         uniqueDepartement.value === departement.hireNumDepartement
    //                 )
    //                 if (!foundDepartement) {
    //                     uniqueDepartement.push({
    //                         value: departement.hireNumDepartement,
    //                         label: departement.hireNumDepartement
    //                     })
    //                 }
    //                 return uniqueDepartement
    //             }, [])
    //         newDepartements.sort((a, b) => a.label.localeCompare(b.label));
    //         if (newDepartements.length > 0) {
    //             setDepartements(newDepartements)
    //         } else {
    //             setDepartements()
    //         }
    //     }
    //     // eslint-disable-next-line
    // }, [selectRegion])
                                    */
