// REACT !
import React, { useId, useMemo, useState } from "react"
//Hook Contexte Global
import useGlobalContext from "../../../Hooks/useGlobalContext"

function Inputs({
    // user data when needed (ie for filtering and stuff)
    data,
    value,
    // all the input data
    entries,
    // functions depending on where we use it.
    handleChange,
    // personalisation in top level
    style,
    pClassName,
}) {

    // modification des couleurs de thèmes.
    const { theme } = useGlobalContext()

    const [focusedInput, setFocusedInput] = useState(null);

    const id = useId()

    ////////////////
    // JSX
    ////////////////

    return useMemo(() => (
        <>
            {entries
                ?.map((entry, index) => (

                    <p className={`pos-r ${pClassName}`} key={`${entry.name}-${id}-${index}`}>

                        <input className={`input-studiel ${theme}`}
                            style={style}
                            id={entry?.name}
                            // primordial pour la récuperation d'info
                            name={entry?.name}
                            type={entry?.type}
                            placeholder={entry?.placeholder}
                            // fonction récuperatrice d'infos
                            onChange={handleChange}
                            required={entry?.required}
                            // mettre en auto=off dans l'array si vous en voulez pas. 
                            autoComplete={entry?.auto}
                            // bulle info
                            title={entry?.title}
                            // verification pattern pour l'input valide
                            pattern={entry?.pattern}
                            value={value}
                            onFocus={() => setFocusedInput(entry.name)}
                            onBlur={() => setFocusedInput(null)}
                        />

                        <span className={`label-studiel ${theme} ${focusedInput === entry.name ? "focus" : ""}`}>

                            {entry?.label}

                        </span>

                        {/* Verification && messages d'erreurs  */}
                        {data
                            && entry.reg
                            && Object.keys(data).includes(entry?.name)
                            && !entry?.reg.test(data[`${entry.name}`])
                            &&
                            <div className={`text-error font-065 mt-050`}>{entry?.title}</div>
                        }

                    </p>

                )
                )
            }
        </>
        // eslint-disable-next-line
    ), [entries, style, theme, value, focusedInput])
}

export default Inputs;