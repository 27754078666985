function SSERP({ array }) {

    return (

        <div className={`col-12 sserp-wrapper pb-2 mt-3 pt-3`}>

            {array
                ?.map((elem, index) => (

                    <div key={index} className={`pos-r col-2-xxl col-2-xl col-12-lg col-12-md col-12-sm col-12-xs col sserp-general-wrapper`}>

                        <div className={`col-9 fd-c j-space-between ai-center sserp-text-img-div`}>

                            <img src={elem.src}
                                style={{ width: "55px", paddingBottom: "20px" }}
                                alt={`SSERP icon ${elem.text}`}
                                className={`sserp-icons-responsive`} />

                            <p className={`col-10 sserp-text`} style={{ fontSize: "16px" }}>

                                {elem.text}

                            </p>

                        </div>

                        <div className={`sserp-circle-number center text-white fw-b br-grey-5 z-100
                        ${index % 2 === 0 ? "bg-primary-color-4" : "bg-primary-color-5"}`}
                        >

                            <span className={`sserp-number`}>
                                0{index + 1}
                            </span>

                        </div>

                        <span className={`sserp-circle-number-bar ${index % 2 === 0 ? "bg-primary-color-4" : "bg-primary-color-5"}`} />

                        <div className={`col-12 sserp-svg`}>

                            <svg width="121" height="37">

                                <polygon points="0,10 26,8 26,36" fill="lightgrey" />
                                <polygon points="26,0 75,0 87,18 75,37 26,37"
                                    fill={` ${index % 2 === 0 ? "#EE7C00" : "#E9590B"}`} />
                                <polygon points="88,0 100,18 88,37 109,37 121,18 109,0"
                                    fill={` ${index % 2 === 0 ? "#EE7C00" : "#E9590B"}`} />

                            </svg>

                        </div>

                    </div>
                ))}
        </div>
    );
}

export default SSERP;