import React from "react";

import useGlobalContext from "../../../Hooks/useGlobalContext"

const Text = React.forwardRef(({
    text,
    style,
    handle,
    children,
    cssDiv = "",
    css = "",
    textDark = "text-white",
    textLight = "text-black",
}, ref) => {

    const { theme } = useGlobalContext()

    return (

        <>
            {cssDiv ?

                <div className={`${cssDiv}`} >

                    <p className={`text-global-size ${theme === "dark" ? textDark : textLight} ${css}`}
                        onClick={handle}
                        style={style}
                        ref={ref}
                    >

                        {children}

                        {text}

                    </p>

                </div >

                :

                <p className={`text-global-size ${theme === "dark" ? textDark : textLight} ${css}`}
                    onClick={handle}
                    style={style}
                    ref={ref}>

                    {children}
                    {text}

                </p>

            }

        </>

    );
})

export default Text;