import { useState, useEffect } from "react"

/* Change color themes yheaaaay */

function useTheme() {

    const getTheme = () => {
        return localStorage.getItem('@theme-studiel')
    }

    const [theme, setTheme] = useState(() => getTheme())


    useEffect(() => {

        if (theme === null || theme === "null") {
            localStorage.setItem('@theme-studiel', "white")
        } else {
            localStorage.setItem('@theme-studiel', theme)
        }

    }, [theme])


    return { theme, setTheme }

}

export default useTheme; 