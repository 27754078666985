// Hook React
import React, { useId } from "react";

function Selector({
    // Types of data
    entries,
    // used for display text (like in fiche assignation)
    // functions we can pass trough depending where and how we use the selector
    addSelectByEvent,
    // Some personaslisation props if you need to. Better go trought the css types in entries. 
    empty,
    css,
    value
}) {

    ////////////////
    // Id for mapping witht he inbuild react useId hook
    ////////////////

    const id = useId()

    ////////////////
    // JSX
    ////////////////

    return (

        <div className={`pos-r m-1`}>

            <select
                value={value || ""}
                className={`selector-studiel p-2 ${css}`}
                onChange={addSelectByEvent}>

                <option value="" data-name={null}>{empty}</option>

                {/* When there's a list hard coded in the array*/}
                {entries?.map((elem, index) => (

                    <option key={`${id}-${index}`}
                        value={elem?.value}
                        data-name={elem?.value}
                        disabled={false}
                        className={`tt-c`}
                    >

                        {elem?.label}

                    </option>

                ))}

            </select>

            <span className={`custom-caret`}></span>
        </div>


    );
}

export default Selector;