// Layout Comoponent
import CardLayout from "../Shared/Layout/CardLayout";

// Nav Top
import LandingNav from "../LandingPage/LandingElements/LandingNav";

// Simple Components
import Title from "../Shared/Basic/Title";
import Text from "../Shared/Basic/Text";

// Function to make things bold
import MakeBold from "../../Functions/MakeBold";

import useLanguage from "../../Hooks/useLanguage";
// import { legalNoticesArray } from "../../JSON/FR/MentionsLegale";

function LegalNotice() {


    const { languageArray: legalNoticesArray } = useLanguage("MentionsLegale", "legalNoticesArray")
    // Terms to make bold
    const listOfBoldTerms = ["studiel", "numéro de téléphone", "siège social"]

    // JSX

    return (

        <>

            <LandingNav type={`not-principal`} title={`Mentions Légales`} colorTitle={`text-white`} />

            <CardLayout css={`center fd-c`}>

                <CardLayout css={`mt-3 display-f fd-c cent col-10`}>

                    {legalNoticesArray?.map((point, index) => (

                        <CardLayout key={index} css={`ml-3`}>

                            <Title El={`h3`}>
                                {point.title}
                            </Title>

                            <Text css={`prewrap pt-1`}>
                                <MakeBold text={point.text} terms={listOfBoldTerms} />
                            </Text>

                            <ul>
                                {point.list.map(list => (

                                    <li key={list}>
                                        <Text>
                                            - <MakeBold text={list} terms={listOfBoldTerms} />
                                        </Text>
                                    </li>
                                ))}

                            </ul>

                        </CardLayout>

                    ))}

                </CardLayout>

            </CardLayout>

        </>
    );
}

export default LegalNotice;