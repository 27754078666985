import { useState } from 'react';

import './css/App.css';
// scss
import './css/index.css'
import "./css/composant/carrousel.css"

// Global Context 
import useGlobalContext from './Hooks/useGlobalContext';

// Pages
import Landing from './Components/LandingPage/Landing';
import Hiring from './Components/Hiring/Hiring';
import LegalNotice from './Components/Legal/LegalNotice';
import RGPD from './Components/Legal/RGPD';
import Contact from "./Components/Contact/Contact"
import SingleOffer from './Components/Hiring/HiringElements/SingleOffer';
import NoRoute from './Components/Shared/NoRoute';

// Footer component
import Footer from './Components/Shared/Footer';

//React router 6 components / elements1
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Admin from './Components/Admin/Admin';
import Meca from './Components/Metiers/Meca';
import Syst from './Components/Metiers/Syst';
import Info from './Components/Metiers/IT';
import Prod from './Components/Metiers/Prod';
import Groupe from './Components/Groupe/Groupe';
import Realisations from './Components/Relaisations/Realisations';

const router = createBrowserRouter([
  { index: "/", element: <Landing /> },
  { path: "hire", element: <Hiring /> },
  { path: "mentions-legales", element: <LegalNotice /> },
  { path: "rgpd", element: <RGPD /> },
  { path: "contact", element: <Contact /> },
  { path: "meca", element: <Meca /> },
  { path: "prod", element: <Prod /> },
  { path: "info", element: <Info /> },
  { path: "syst", element: <Syst /> },
  { path: "groupe-page", element: <Groupe /> },
  { path: "realisations", element: <Realisations /> },
  { path: "offer/:offerId", element: <SingleOffer /> },
  { path: "admin", element: <Admin /> },
  { path: "*", element: <NoRoute /> },
],);

function App() {

  // Theme changer

  const { theme } = useGlobalContext()

  const [debugMode] = useState(false)

  return (

    <>

      <main className={`main 
        ${theme === "dark" ? "bg-primary-bg" : "bg-white"} 
        ${debugMode ? 'debug-mode' : ''}`}>

        <RouterProvider router={router} />;

      </main>

      <Footer />

    </>
  );
}

export default App;

///////////////
// TODO
///////////////

// UN JOUR : PASSER A TAILWIND

///////////////
// FAIT IL Y A UN MOMENT
///////////////

// titres pages principale => aérer
// Timeline => responsive à revoir, 125%, 150% check check check.
// Probablement devoir récuperer les differentes tailles et ajuster le timeline-text sur les différentes views
// Egalement revoir comment la timeline en elle même est placée au milieu de la div avec l'image en fond...

///////////////
// FAIT 24/10/23
///////////////

// (mini) LOGO TROP GROS
// Remplacer les logos par les contours blancs
// Remplacer vidéo plus fallback
// Studiel groupe : images "notre gouvernance" mettent trop de temps à charger (fond / images) ==>
///// Réduire le maximum d'images en webp pour augmenter la vitesse de chargement
// Décoler les boites chiffres un peu a droite et a gauche (stats tiles) -mobile-
// Augmenter la "vue" dans la page métier pour l'apparition des boites (animations) - useObserver
// Revoir les box avec nos inventeventions -mobile- -> Reduire les photos avec les métiers => fix en fixant en pixel x)
// Les stats en zoomé 150% <--- URGUGUGG !! ==> FIXE'd

///////////////
// PENDING / STATUS
///////////////

///////////////
// MOBILE
///////////////

///////////////
// FAIT 06/10/23
///////////////

// TESTIMONY
// => Visuel pour voir quand on est en mobile
// => défilement automatique

// ENLEVER LE PIE => METTRE LES BOITES. D'abord ingé puis technicien en mobile.
// Boites en cours de refonte, cambembert retriré. (mettre une animation quand on tombe dessus, genre du pop in ?)
// Repasser dessus pour le responsive : augmenter la taille de l'image en fonction des breakpoints.
// Fait en anglais !
// C'est à peu près fini en responsive (un peu grand, pas le temps de me pencher dessus en profondeur pour le moment)

// METTRE LES ANNOCNES EN DUR (POUR LE MOMENT)

// METTRES LES ACTUS QU'EVA ENVOIE EN DUR =>
// CHANGEMENTS SE FERAIENT QU'UNE FOIS TOUTES LES DEUX SEMAINES
// VOIR AVEC DAVID

// Trop de blanc => nos engagements santé sécurité => moins de blanc !

// Traduction : agrandir, fermer dans SystemManagement.jsx

// Alignements => case 20% dépasse un peu

// Managment systeme => réduire espace au dessus

// Contact. Plus besoin de scroller, tout voir d'un coup

// Aggrandir ya pas deux G !!

// Réduire l'espaces au dessus de "nos certifications"
//// Mobile
// Logos certifications => texte d'abord, logo ensuite

// Page Métiers =>
// ELEC =>  Faire un "' lire la suite' pour chacun des logiciels embarqués"
// En ouvrir un par un.
// Production => Inverser les deux cases. (Pour tester) => Réduire la case ou ya le lire la suite => Pour qu'on ne voit que

// Studiel s'engage => icones plus gros plus à gauche
// Même le texte au pire.
// Voir les logos en gras => NOT POSSIBLE

///////////////
// MOBILE
///////////////

// A partir de 720
// faire en sorte que ce titre soit comme ça, toujours en mobile ... :D
// NOTRE POLITIQUE SSERP 2021 - 2023
// SANTE, SECURITé, ENVIRONEMENT
// & Radioprotection

// MOBILE => TIMELINE
// Réduire la taille de la boite qui défile.
// C'est léger mais pour que ça aille sur toutes les dimensions j'ai quand même du adapter

// Nos expertise, trop de blanc en mobile

// NOS PRINCIPAUX MODES D'INTERVENTION titre // > Centrer

// Gouvernance
// Titres plus petit => j'avais oublié le responsive

// Contact =>
// Les guillets qui déconnent en mobile.

//NOS MODES D'INVERVENTION
// Responsive, menu qui déconne en dessous (agrandir l'image)

// Nos valuers => décoler le texte en mobile

// MECA => Harmoniser les espaces en dessous des points
// c'est harmonisé, recheck avec la bonne police.
// harmoniser dans la version anglaise également x)

// INFO => Harmoniser les textes en et espaces.
// c'est harmonisé, recheck avec la police

///////////////
// FAIT BEFORE
///////////////

// Lancement pour l'évenement.

// LANDING NAVIGATION (mobile : burger) =>
// FERMER LA MODALE QUAND ON CLIQUE SUR NOS IMPLANTATIONS
// mettre un icone classqique, couleurs studiels ?
// blanc ou noir ?
// Avec écris Studiel à coté.
// => on garde le "burger" coloré, et c'est en place

// LANDING ACTUALITES
// Faire comme pour les témoignages lorsque l'on réduit la page (lorsque à peu près on arrive au format tablette) => ça m'a l'air ok.
// NOTE : Ya pas de fleches pour le moment dans la mesure ou il n'y aura que trois actualités.

/// LANDING SINGLE (carrousel) =>
// Replacer les dots. padding -> rempalcés
// Taille texte refaire l'espace blanc. (mobile) -> ça à tout suivi
// Refaire taille photo responive  -> pas eu besoin

// LANDING PIE (camembert) =>
// Remonter les deux cases en mode téléphone -> (en fonction de la largeur de l'écran) done ?
// Camembert : répartitions : police responsive

// LANDING TILES (stats) =>
// Sur téléphone : mettre les cases toutes à la même taille -> done ?

// LANDING CONTACT (contact) =>
// Nous recrutons, retapper le texte en géréral en fait. Il fait ce qu'il veut. -> il fait moins ce qu'il veut

// LANDING STAT TITLES (nos chiffres clefs) =>
// REVOIR CA
//   .round-container {
//     aspect-ratio: 1/1; <----- testé avec un wekbit // a tester avec un iphone

// LANDING TIMELINE =>
// Les chiffres qui font ce qu'ils veulent. : 2015-2021 => texte disparait a 1920 => checkkkker
// selectionner directement 2023 en mobile.
// Checker sur Iphone de Thomas

// LANDING TESTIMONY (ce sont eux qui en parlent les mieux):
// Remettre les fleches.
// => Reduire l'espace. en hauteur
// => soit augmenter la police
// probalbmenet les deux
// Mobile => réduire l'espace en dessous du titre et en dessous de tous
// => j'ai modifier un poil les deux, mais pour que ça rende toujours de la même taille en desktop, j'ai du laisser une valeure de base en hauteur pareille pour tous (sinon ils auraient tous une hauteure différente)

// SINGLEOFFER (offre simple) =>
// Tout sur une page. ++ le footer PLIZ =>> Alors j'ai tout mis sur une page, sauf le footer et ce en 1920. A voir si on laisse comme ça ou si on réduit la taille des textes du coup ou la taille des images (probablement les deux)
// => je suis rerepasser dessus, j'ai réduit un tout petit peu le texte de "mission" avec "lire la suite" on voit un bout

// PAGES : METIERS :
// ==> réduire l'espace entre les chiffres et les textes
// quit a réduire la taille de la case.
// et ou augmenter la taille de la police.
// Le but c'est que ce soit harmonieux
// Les textes sempblent justifier ou center ou les deux.
// => le texte passe en overlfow hidden MEME quand c'est en desktop. => et ça marche !
// Cases couleurs en mobile, tester le texte noire, l'overlay transparent.
// tester les couleurs d'origines en transparence plutot que le gris pas beau
// Message de Jocelyne : "oui c'est bien" => admis par le conseil !
// PAGE METIERS MEC =>
// Repostionner les textes en face des icones => c'est en face !
// Mobile - Tabelettes : essayer d'intégrer les icones dans les textes => en test pour le moment
// Voir ce qu'on fait avec le "lire la suite" sur la page prod, qui dépasse => overflow scroll pour tout le monde

// PAGE LE GROUPE
// MANAGEMENT SYSTEME
// => avec message en click pour ouvrir en mode paysage dans une modale. => à partir de 800px, il y a un petit lien pour ouvrir une fenêtre pour pouvoir agrandir l'image. celle-ci se mets en rotation de 90degrés pour prendre tout l'écran
// => GENERAL
// Virer les images BE et CONSEIL en mobile => BYE
// => VALEURS
// image on top => la virer quand je sais plus quoi en faire. => virée
// Titre => moins de margin au dessus et en dessous (laisser la taille du padding) => On parlait bien du mobile ? J'ai retiré tout l'éspacement, ça m'a l'air bien
// SSERP
// => tout ouvrir ++ virer le pictogramme (mobile)
// MANAGEMENT SYSTEME
// => virer la version tablette et mobile.
// => Remplacer par image
// MODES D'INTERNVENTIONS
// => modifier images
// STUDIEL S'ENGAGE =>
// => rajouter les icones quand envoyés
// => logo => changement tailles

// SITE GLOBAL
//  <TextGroup /> faire la police responsive globale

// Installer Safari ??
// => je peux pas l'installer

//////////////////- Laters -/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////
// 'DETAILS' :
///////////////

// Modal "devouvez nous" => couper le scroll d'arrière plan quand elle est ouverte.
// Modal : revoir la taille quand mobile
// Check RGPD, partie technique
// /!\/!\ Page Electronique // Meca : récupérer les vrais logos et les intégrer proprement !!! /!\/!\

// SEO
// React Snap => à tester (SEO FRIENDLY STUFFY STUFF )=> EN COURS

///////////////
// TODO : -- LATERS --
///////////////

// Finir le dernier word par Jocelyne && Eva ?? (le retrouver ?)
// Manque ce qui était en jaune au final (Je pense c'est les photos)