// Simple Components
import TextGroup from "../../Shared/Basic/TextGroup"
import Title from "../../Shared/Basic/Title"

const StakesCards = ({ item }) => {

    // JSX // JSX // JSX // 
    // JSX // JSX // JSX // 
    // JSX // JSX // JSX // 

    return (

        <div className={`pt-3 col col-4-xxl col-4-xl col-8-lg col-8-md col-10-sm col-12-xs j-space-between ai-center`}>

            <div className={`col-12 center`} style={{ height: "12vh" }}>

                <Title El={`h3`}
                    css={`t-center prewrap font-sub-title-group text-white col-12`}>

                    {item.title}

                </Title>

            </div>

            <div className={`mt-3 lindeheiht18 qhsse-card-text-div br-white-3 br-lg prewrap col-10-xxl col-10-xl col-10-lg col-12-md col-12-sm col-12-xs`}>

                <TextGroup css={`qhsse-card-text t-center  text-white`}>

                    {item.text}

                </TextGroup>

            </div>

        </div>
    )
}

export default StakesCards