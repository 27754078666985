import { useState, useEffect } from "react";

// Layout component
import CardLayout from "../../Shared/Layout/CardLayout";

// simple component
import Title from "../../Shared/Basic/Title";
import Text from "../../Shared/Basic/Text";
import Btn from "../../Shared/Basic/Btn";

// big bar on top
import LandingNav from "../../LandingPage/LandingElements/LandingNav";

import TruncatableText from "../../Shared/Basic/TruncatableText";

// personal fetch hook
//import useFetch from "../../../Hooks/useFetch";

import { useParams } from "react-router-dom";

// imports from the same file
import { joinus, offerfirst, recrutementEmail } from "../../../generalimports";

// personal language hook
import useLanguage from "../../../Hooks/useLanguage";
// import { generalTranslate } from "../../../JSON/FR/GeneralArray";

// personal fetch hook
import useFetch from "../../../Hooks/useFetch";

// package to parse hmtl in strings
import { Interweave } from "interweave";


// BIG EMAIL BUTTON (generates an email btw)
const EmailButton = ({ data }) => {

    const { languageArray: generalTranslate } = useLanguage("GeneralArray", "generalTranslate")

    const { hireRef, hireTitle } = data;

    const subject = `${generalTranslate?.subject} ${hireRef} ${hireTitle}`;
    const body = `${generalTranslate?.bodypart1} 
    
    ${generalTranslate?.bodypart5}  ${hireRef}

    ${generalTranslate?.bodypart6} 

    ${generalTranslate?.bodypart4}
    `

    const mailtoLink = `mailto:${recrutementEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    return (

        <a className={`col-12 center pb-1`}
            href={mailtoLink}>

            <Btn cssDiv={`display-f j-flex-end text-white mt-2`}
                css={`font-lg`}>

                {generalTranslate?.apply}

            </Btn>

        </a>
    );
};


// PRINCIPAL COMPONENT
function SingleOffer() {

    const { languageArray: generalTranslate } = useLanguage("GeneralArray", "generalTranslate")

    // FETCHING GROOVING AND MOOVING
    const { data: allOffers, pending, error } = useFetch("/JSON/HireList.json")

    const [hiringSingleData, setHiringSingleData] = useState()

    // WILL BE NEEDED TO FETCH ON 
    const { offerId } = useParams()

    // FOR NOW I'VE PUT ONLY ONE OFFER IN THIS
    const handleApply = (offer) => {

        const subject = `${generalTranslate?.subject} ${offer.hireRef} ${offer.hireTitle}`;
        const body = `${generalTranslate?.bodypart1} 
    
        ${generalTranslate?.bodypart5}  ${offer.hireRef}
    
        ${generalTranslate?.bodypart6} 
    
        ${generalTranslate?.bodypart4}
        `
        const mailtoLink = `mailto:${recrutementEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        const link = document.createElement('a');
        link.href = mailtoLink;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    };

    useEffect(() => {

        if (allOffers && allOffers.length > 0) {
            const selectedOffer = allOffers.find(elem => elem.hireId === offerId)

            setHiringSingleData(selectedOffer)
        }

    }, [offerId, allOffers])

    useEffect(() => {
        if (hiringSingleData) {
            document.title = `Offre ${hiringSingleData.hireTitle}`
        }
    }, [hiringSingleData])

    // JSX  // JSX  // JSX
    // JSX  // JSX  // JSX
    // JSX  // JSX  // JSX

    const title = !pending && hiringSingleData && `<span className="fw-400">${hiringSingleData?.hireTitle}</span><br/><span className="fw-400 o-70">${hiringSingleData.hireCity} - ${hiringSingleData.hireRef}</span>`

    return (

        <>

            <LandingNav
                handleApply={() => handleApply(hiringSingleData)}
                titlePlacement={`offer`}
                type={`not-principal`}
                colorTitle={`text-white`}
                title={title}
                bgLightMode={`bg-primary-color-4`}
            />

            <CardLayout p=""
                css={`center col`}>

                <CardLayout css={`col-12 row center`}>

                    <CardLayout p=''
                        css={`col-6-xxl col-6-xl col-6-lg col-12-md col-12-sm col-12-xs center col `}>

                        <div>
                            <Title El={`h2`}
                                css={`display-f fd-c pt-1 pb-1`}>

                                {generalTranslate?.mission}

                            </Title>

                            <Text css={`prewrap pb-1`}>

                                {error && <>{error}</>}

                                {pending && <>{generalTranslate?.loading}</>}

                                {!pending && hiringSingleData &&
                                    <TruncatableText text={hiringSingleData?.hireMission}
                                        maxWords={70} />
                                }

                            </Text>

                            <Title El={`h2`}
                                css={`display-f fd-c pt-1 pb-1`}>

                                {generalTranslate?.profile}

                            </Title>

                            <Text css={`prewrap`}>

                                {/* {error && <>L'annonce que vous cherchez n'existe pas</>}

                                {pending && <>{generalTranslate?.loading}</>} */}

                                {hiringSingleData?.hireProfile}

                            </Text>
                        </div>

                    </CardLayout>

                    <CardLayout css={`col-6-xxl col-6-xl col-6-lg col-12-md col-12-sm col-12-xs center`}>

                        <img src={offerfirst}
                            alt={`studiel single offer`}
                            style={{ maxHeight: "35vh", maxWidth: "100%", objectFit: "cover" }} />

                    </CardLayout>

                </CardLayout>

                <CardLayout css={`col-12 fd-rev center`}>

                    <CardLayout p=''
                        css={`col-6-xxl col-6-xl col-6-lg col-12-md col-12-sm col-12-xs center col`}>

                        <div>
                            <Title El={`h2`}
                                css={`display-f fd-c pt-1 pb-1`}>

                                {generalTranslate?.whoAreWe}

                            </Title>

                            <Text css={`prewrap mb-2 p-1`}>

                                {generalTranslate?.whoAreWeText}

                            </Text>

                            <Title El={`h2`}
                                css={`display-f fd-c pt-1 pb-1`}>

                                {generalTranslate?.whatWeOffer}

                            </Title>

                            {generalTranslate && generalTranslate.whatWeOfferText &&
                                <Text css={`prewrap t-justify p-1`}>

                                    <Interweave content={generalTranslate?.whatWeOfferText} />

                                </Text>
                            }
                        </div>

                    </CardLayout>

                    <CardLayout css={`col-6-xxl col-6-xl col-6-lg col-12-md col-12-sm col-12-xs center`}>

                        <img src={joinus}
                            alt={`studiel single offer`}
                            style={{ maxHeight: "40vh", maxWidth: "100%", objectFit: "cover" }} />

                    </CardLayout>


                </CardLayout>

                {hiringSingleData && <EmailButton data={hiringSingleData} />}

            </CardLayout >
        </>

    );
}


export default SingleOffer;