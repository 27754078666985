import { useState } from "react";

import { generalTranslate } from "../../../JSON/FR/GeneralArray";

function TruncatableText({ text, maxWords }) {

    const [isTruncated, setIsTruncated] = useState(true);

    const words = text?.split(' ');
    const isOverMaxWords = words?.length > maxWords;

    const displayText = isTruncated ? words?.slice(0, maxWords).join(' ') : text;

    return (
        <>

            {displayText} {" "}

            {isOverMaxWords &&

                <span
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsTruncated(!isTruncated);
                    }}
                >
                    {isTruncated ?

                        <span className={`fw-b p-1 display-b pointer`}>

                            {generalTranslate?.readMore}

                        </span>

                        :

                        <span className={`fw-b p-1 display-b pointer`}>

                            {generalTranslate?.readLess}


                        </span>
                    }
                </span>
            }

        </>
    );
}

export default TruncatableText;