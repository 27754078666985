import { useEffect, useState } from "react";

import CardLayout from "../Shared/Layout/CardLayout";

import Btn from "../Shared/Basic/Btn";
import Text from "../Shared/Basic/Text";
import TextGroup from "../Shared/Basic/TextGroup";

import useFetch from "../../Hooks/useFetch";
import useSubmit from "../../Hooks/useSubmit"

import { coffee } from "../../generalimports";

const ActuBox = ({
    actu,
    type,
    handleActuBox,
    selectedActu,
    handleCheckBox
}) => {

    let newActu
    if (selectedActu && selectedActu.length > 0) {
        newActu = selectedActu?.find(
            newActu => newActu.actualityGuid === actu.actualityGuid
        )
    }

    const types = (type === "selection" || type === "aviable")

    return (
        <>
            <CardLayout css={`col-4`}>

                <div className={`col-12 center br-black-1 p-2 col pointer ${(types && newActu) ? "bg-grey-dark-2" : "bg-white"}`}
                    style={{ minHeight: "350px" }}
                    onClick={() => handleActuBox?.(actu.actualityGuid)}>

                    <Text css={`t-justify col-12`}>
                        {actu?.actualityBody}
                    </Text>

                    <img className={`img-actu`}
                        src={actu.actualitySrc ? actu.actualitySrc : coffee}
                        alt={`linkedin - annonces - actualités - studiel`} />

                </div>

                {type !== "selection" ? null

                    :

                    <label className={`row j-space-around`}>

                        <Text>Offre d'emploi</Text>

                        <input type={`checkbox`} onChange={() => handleCheckBox?.(actu.actualityGuid)} />

                    </label>

                }

            </CardLayout>
        </>

    )
}

function Admin() {

    const [logged, setIsLogged] = useState(false)
    const [logginData, setLoginData] = useState({})

    const [selectedActu, setSelectedActu] = useState([])

    const {
        data: actuData,
        pending: pendingActu,
        error: errorActu
    } = useFetch(`${import.meta.env.VITE_APP_API}/api/LinkedinPDO/linkedin-actuality`, logged)

    const { data: actualData,
        //  pending, error 
    } = useFetch("/JSON/LinkedinData.json")

    const [okMsg, setOkMsg] = useState()

    useEffect(() => {
        setSelectedActu(actualData)
    }, [actualData])


    const handleActuBox = guid => {
        const findGuid = selectedActu?.find(selectActuGuid => selectActuGuid.actualityGuid === guid)?.actualityGuid

        if (findGuid) {
            const indexFind = selectedActu.findIndex(selectActuGuid => selectActuGuid.actualityGuid === guid);
            if (indexFind !== -1) {
                const newActu = [...selectedActu];  // Create a copy of selectedActu
                newActu.splice(indexFind, 1);  // Remove the element from the copy
                setSelectedActu(newActu);  // Update the state with the modified array
            }

        } else if (!findGuid && selectedActu.length < 3) {
            const findNewActu = actuData.find(newlySelectedGuid => newlySelectedGuid.actualityGuid === guid)
            const newActu = [...selectedActu]
            newActu.push(findNewActu)
            setSelectedActu(newActu)
        }
    }

    const handleCheckBox = guid => {
        const newActu = selectedActu.map(actu => {
            if (actu.actualityGuid === guid) {
                return {
                    ...actu,
                    actualityType: actu.actualityType === null ? "offer" : null
                };
            }
            return actu;
        });

        setSelectedActu(newActu);
    }

    const { handleSubmit, resMsg } = useSubmit()
    const { handleSubmit: handleAddActu, resMsg: resMsgActu } = useSubmit()

    // console.log("RESMSG ADMIN", resMsg)
    const handleSubmitLoggin = e => {
        handleSubmit({
            e,
            url: `${import.meta.env.VITE_APP_API}/api/Administration/login-admin`,
            body: logginData
        })

    }

    const handleSubmitActu = e => {
        handleAddActu({
            e,
            url: `${import.meta.env.VITE_APP_API}/api/Administration/linkedin-new`,
            body: selectedActu
        })
    }

    useEffect(() => {
        if (resMsg && resMsg.ok) {
            setIsLogged(true)
        }
    }, [resMsg])

    useEffect(() => {
        if (resMsgActu && resMsgActu.ok) {
            setOkMsg("Envoyé")
        }
    }, [resMsgActu])

    if (pendingActu) return (<CardLayout style={{ minHeight: "80vh" }} css={`center`}>Chargement ...</CardLayout>)
    if (errorActu) return <>{errorActu.toString()}</>

    return (

        <>

            {logged ?

                <>
                    <CardLayout css={`col-12 center col`} p='' style={{ minHeight: "90vh" }}>

                        <TextGroup css={`font-lg center p-2 mt-2`}>Actualités sur la page principale</TextGroup>

                        <CardLayout css={`col-10 center row br-error-5 m-3`}>

                            {actualData && actualData.length > 0 &&

                                actualData?.map((actu, index) => (

                                    // HERE ADD OR DELETE ANY ACTU ON CLICK
                                    <ActuBox actu={actu}
                                        key={index}
                                        selectedActu={selectedActu}
                                        type={`actual`}
                                    />

                                ))}

                        </CardLayout>
                        <div className={`display-f col col-8`}>
                            <TextGroup css={`font-lg center p-2`}>Dernières actualités Linkedin</TextGroup>
                            <TextGroup>- Cliquez sur une actualité grisée pour la déséléctionner.</TextGroup>
                            <TextGroup>- Cliquez sur une actualité blanche pour la séléctionner.</TextGroup>
                            <TextGroup>- Séléctionnez en trois pour pouvoir valider.</TextGroup>
                        </div>

                        <CardLayout css={`col-10 center row br-green-5 m-3`}>

                            {actuData && actuData.length > 0 &&

                                actuData?.map((actu, index) => (


                                    // HERE ADD OR DELETE ANY ACTU ON CLICK
                                    <ActuBox actu={actu}
                                        key={index}
                                        selectedActu={selectedActu}
                                        type={`aviable`}
                                        handleActuBox={handleActuBox} />

                                ))}

                        </CardLayout>

                        <div className={`display-f col col-8`}>
                            <TextGroup css={`font-lg center p-2`}>Actualités après validation</TextGroup>
                            <TextGroup>- Cliquez sur une actualité grisée pour la déséléctionner.</TextGroup>
                            <TextGroup>- Si l'offre concerne une offre d'emploi, séléctionnez la case à cocher. (cella affichera un overlay "offre d'emploi, en reverra vers la page "nous recrutons")</TextGroup>
                        </div>

                        <CardLayout css={`col-10 center row br-primary-color-4-5 m-3`}>

                            {selectedActu && selectedActu.length > 0 &&

                                selectedActu?.map((actu, index) => (

                                    // HERE DELETE ANY ACTU ON CLICK
                                    <ActuBox
                                        actu={actu}
                                        key={index}
                                        selectedActu={selectedActu}
                                        type={`selection`}
                                        handleActuBox={handleActuBox}
                                        handleCheckBox={handleCheckBox}
                                    />

                                ))}

                            {selectedActu && selectedActu.length === 0 &&

                                <TextGroup>Acune actualité séléctionnée</TextGroup>

                            }

                        </CardLayout>

                    </CardLayout>


                    <div className="center m-2 p-2">

                        <Btn
                            disabled={!(selectedActu.length === 3)}
                            cssDiv={``}
                            css={`font-xl`}
                            handleClick={handleSubmitActu}>

                            {!(selectedActu.length === 3) ?

                                <>Veuillez sélectionner trois actualités</> : <>Valider</>

                            }


                        </Btn>

                    </div>

                </>

                :

                <CardLayout css={`col-12 center col`} p='' bg={`bg-grey-dark-7`} style={{ minHeight: "90vh" }}>

                    <form className="col" onSubmit={handleSubmitLoggin}>

                        <label> <TextGroup css={`font-md text-white`}>Log in</TextGroup>

                            <input type='text'
                                className={`input`}
                                placeholder="email"
                                name='Name'
                                onChange={e => setLoginData(data => ({ ...data, [e.target.name]: e.target.value }))} />

                        </label>

                        <label> <TextGroup css={`font-md text-white`}>Mot de passe</TextGroup>

                            <input type='password'
                                className={`input`}
                                placeholder="*****" name="Password"
                                onChange={e => setLoginData(data => ({ ...data, [e.target.name]: e.target.value }))} />

                        </label>

                        <div className="center p-2">

                            <Btn type='submit'>Valider</Btn>

                        </div>

                    </form>

                </CardLayout>
            }

            {okMsg &&

                <>Modification faite !</>
            }

        </>

    );
}

export default Admin;