import useGlobalContext from "../../../Hooks/useGlobalContext";


function Btn({
    children,
    handleClick,
    disabled = false,
    cssDiv = "",
    css = "",
    type = "",
    style = "",
    btnStyleLight = "btn-primary-color-4",
    btnStyleDark = "btn-primary-color-2 text-white"
}) {

    const { theme } = useGlobalContext()

    return (

        <div className={`${cssDiv}`} style={{ ...style }}>

            <button disabled={disabled}
                type={type}
                onClick={handleClick}
                className={`fw-b br-xl ${css} ${theme === "dark" ? btnStyleDark : btnStyleLight}`}>

                {children}

            </button>

        </div>


    );
}

export default Btn;