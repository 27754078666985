import TextGroup from "./Basic/TextGroup";
import CardLayout from "./Layout/CardLayout";

import { studielOldLogo } from "../../generalimports";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";


function NoRoute() {

    const [count, setCount] = useState(5)

    const nav = useNavigate()

    useEffect(() => {

        setTimeout(() => nav("/"), 5000)

        setInterval(() => setCount(c => c - 1), 1000)
        // eslint-disable-next-line
    }, [])


    return (

        <CardLayout css={`col-12 center col`} style={{ height: '100vh' }}>

            <img src={studielOldLogo} className={`pb-2`} style={{ height: "100px" }} alt={`logo studiel error 404 page`} />

            <TextGroup css={`p-3`}>

                <span className={`text-error`}><span className={`font-xxl`}>Erreur 404</span> : Not Found</span>

            </TextGroup>

            <TextGroup css={`p-3`}>

                La page que vous chercher n'existe pas.

            </TextGroup>

            <TextGroup>

                Vous allez être redirigé automatiquement dans {count} secondes ...

            </TextGroup>

        </CardLayout>

    );
}

export default NoRoute;