import i18nInstance from "../Functions/i18n.js";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useCallback, useMemo } from 'react';

const languageFiles = {
    EN: {
        ContactArray: () => import('../JSON/EN/ContactArray.js'),
        GeneralArray: () => import('../JSON/EN/GeneralArray.js'),
        HireArray: () => import('../JSON/EN/HireArray.js'),
        LandingArray: () => import('../JSON/EN/LandingArray.js'),
        MentionsLegale: () => import('../JSON/EN/MentionsLegale.js'),
        SinglePageArray: () => import('../JSON/EN/SinglePageArray.js'),
    },
    FR: {
        ContactArray: () => import('../JSON/FR/ContactArray.js'),
        GeneralArray: () => import('../JSON/FR/GeneralArray.js'),
        HireArray: () => import('../JSON/FR/HireArray.js'),
        LandingArray: () => import('../JSON/FR/LandingArray.js'),
        MentionsLegale: () => import('../JSON/FR/MentionsLegale.js'),
        SinglePageArray: () => import('../JSON/FR/SinglePageArray.js'),
    }
};

const useLanguage = (fileName, arrName) => {
    const { i18n } = useTranslation();
    const [languageArray, setLanguageArray] = useState();

    const loadLanguageArray = useCallback(async () => {
        await i18nInstance.init();
        const currentLanguage = i18n.language || i18nInstance.options.fallbackLng;
        const language = currentLanguage.startsWith('fr') ? 'FR' : 'EN';
        try {
            if (!languageFiles[language] || !languageFiles[language][fileName]) {
                throw new Error(`Language file not found: ${language}/${fileName}`);
            }
            const module = await languageFiles[language][fileName]();
            if (module && module.default && module.default[arrName]) {
                setLanguageArray(module.default[arrName]);
            } else if (module && module[arrName]) {
                setLanguageArray(module[arrName]);
            } else {
                throw new Error(`Array "${arrName}" not found in ${language}/${fileName}`);
            }
        } catch (error) {
            console.error('Error in useLanguage:', error);
            setLanguageArray(null);
        }
    }, [i18n.language]);

    useEffect(() => {
        loadLanguageArray();
    }, [loadLanguageArray]);

    const memoizedLanguageArray = useMemo(() => ({ languageArray }), [languageArray]);

    return memoizedLanguageArray;
};

export default useLanguage;