const offer = `Au-delà de la rémunération fixe, nous vous offrons une expérience professionnelle enrichissante, alignée sur nos valeurs en matière de Responsabilité Sociale des Entreprises (RSE) et de Qualité de Vie et Conditions de Travail (QVCT). Rejoindre notre équipe, c'est intégrer un environnement où votre développement est au cœur de nos préoccupations.

<span class="fw-b">Avantages : </span>
• Prime Vacances : Profitez d'une prime vacances distribuée deux fois par an.
• Mutuelle d'entreprise : Choisissez parmi trois formules de garanties pour une couverture santé adaptée à vos besoins.
• Carte SWILE : Bien plus qu'une carte restaurant, c’est une application dédiée, qui vous permettre d’accéder à des réductions ainsi qu’à des bons d'achat pour les fêtes. Bénéficiez également de 120€ d'abondement annuel réparti dans les domaines que sont le cinéma, les loisirs et les spectacles.

<span class="fw-b">Engagement RSE et QVT chez Studiel : </span>
• Label Empl'itude : En tant qu'entreprise labelisée, nous sommes fiers de nos actions RSE, démontrant notre engagement envers la responsabilité sociale et sociétale.
• Signataire de la Charte de la Diversité des Entreprises pour la Cité : Notre adhésion à cette charte souligne notre engagement en faveur de la diversité et de l'inclusion au sein de notre équipe.
• Chez Studiel, chaque collaborateur bénéficie d'un accompagnement personnalisé pour son développement professionnel et de formations.

Rejoignez-nous pour faire partie d'une équipe qui vous valorise et contribuez activement à un monde du travail plus responsable et inclusif.

Rejoignez Studiel pour un avenir professionnel épanouissant !
`


export const generalTranslate = {
    title: "Nos offres d’emploi",
    joinUs: "Pourquoi nous rejoindre ?",
    keyWord: 'Mot Clé : nom, déscription, référénce, poste, région, ville ...',
    keyWordShort: "Mot Clés",
    localisation: `Localisation`,
    expertise: "Secteur",
    post: `Type de poste`,
    sector: `Secteur`,
    seeMore: "« Voir plus d’offres »",
    announces: "Annonces",
    nooffers: "Désolé, vos critères ne correspondent à aucune offre actuelle",
    tozero: "Remise à Zéro des critères",
    loading: "Chargement ...",
    spontanous: " Candidature Spontanée",
    bodypart1: "Bonjour,",
    bodypart2: " Je souhaite vous faire parvenir ma candidature spontanée",
    bodypart3: "Vous trouverez ci-joint mon CV.",
    bodypart4: "Cordialement,",
    bodypart5: "Je souhaite postuler à l'offre",
    bodypart6: "Vous trouverez ci-joint mon CV.",
    subject: "Réponse offre",
    mission: "Missions",
    profile: "Profil recherché",
    whoAreWe: "Qui sommes nous ?",
    whoAreWeText: "Studiel est une PME de 200 personnes ayant 2 activités principales que sont l’ingénierie, au travers de nos différents bureaux d’études électronique, mécanique, informatique et produits, et le conseil, en recrutant des Consultants partout en France pour nos clients. Nos agences, réparties sur le territoire, nous permettent un management de proximité.",
    whatWeOffer: "Qu’offrons-nous ?",
    whatWeOfferText: offer,
    // whatWeOfferText: "Au-delà de la rémunération fixe, vous aurez accès à une prime vacances distribuée en 2 fois dans l’année. Aussi, vous aurez accès à une mutuelle d’entreprise, qui vous sera proposée sous 3 formules de garanties, à choisir selon vos besoins. En intégrant Studiel, vous recevrez la carte « SWILE ». Plus qu’une simple carte restaurant, cette dernière vous permettra d’accéder à différents privilèges. Reliée à une application, vous y retrouverez de nombreuses réductions ainsi que des bons d’achat distribués par le CSE pour Noël ou pour les grands moments de votre vie. Vous accéderez également à 120€ d’abondement par an, répartis sur 3 domaines d’activités : le cinéma, les loisirs et les spectacles. Enfin, chaque collaborateur a la possibilité, chez Studiel, d’évoluer grâce à un accompagnement par la formation.  En choisissant Studiel, vous intégrez une entreprise labelisée Empl’itude (certifiant de nos actions RSE) et Signataire de la Charte de la Diversité des Entreprises pour la Cité. Rejoignez-nous !",
    apply: "Postuler",
    readMore: "Lire la suite ...",
    readLess: "Réduire",
    send: "Envoyer",
    follow: "Suivez-nous !",
    rgpd: "Mentions Légales / RGPD",
    emailFrom: "Email from :",
    names: "Mon nom est",
    ofcompany: "de la société",
    mycontacts: "Mes contacts sont",
    grow: "Cliquez pour agrandir",
    close: "Fermer",
    plaquette: "Consultez notre plaquette",
    technique: "Consultez notre présentation technique"

}

