import Title from "../../Shared/Basic/Title"
import TextGroup from "../../Shared/Basic/TextGroup"

const TextAndImage = ({
    text
}) => {

    return (

        <div className={`col-12 row  ${text.textRight ? "fd-rev" : ""}  j-space-evenly ai-center mt-3 pl-2 pr-2`}>

            <div className={`col-8-xxl col-8-xl col-8-lg col-12-md col-12-sm col-12-xs center col`}>

                <Title El={`h3`}
                    css={`font-title-group-sub col-12 p-2`}>

                    {text.subtitleh41}

                </Title>

                <TextGroup css={`text-text-and-image t-justify prewrap p-2`}>

                    {text.textLeft}
                    {text.textRight}

                    <br />

                    {text.navlink ?

                        <span className={`fw-b pointer tdec-u`}
                            onClick={() => window.open(`${text?.navlink}`)}>En savoir plus ...</span>
                        :

                        null
                    }


                </TextGroup>

            </div>

            <div className={`col-2-xxl col-2-xl col-4-lg center remove-960`}>

                <img src={text.img} alt={`Studiel related`}
                    className={`circle-image remove-960`} />

            </div>

        </div>

    )

}

export default TextAndImage