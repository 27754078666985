function CardLayout({
    style,
    children, css = "",
    bg = "",
    El = "div",
    p = "p-1",
    handleMove,
    handleClick,
    id
}) {

    return (

        <El className={`${css} ${p} general-card trans-all-05 ${bg}`}
            style={style}
            onClick={(e) => handleClick && handleClick(e)}
            onMouseMove={(e) => handleMove && handleMove(e)}
            id={id}
        >

            {children}

        </El>

    );
}

export default CardLayout;
