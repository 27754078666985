import useGlobalContext from "../../../Hooks/useGlobalContext"

import Text from "../../Shared/Basic/Text"

import CardLayout from "../../Shared/Layout/CardLayout"

const CardWithNumber = ({
    index,
    data,
    color
}) => {

    const { theme } = useGlobalContext()

    return (

        <CardLayout p="" El={`section`} bg=""
            css={`col-12-xxl col-4-xl col-4-lg col-6-md col-12-sm col-12-xs`}>

            <div className={`col-12 center br-lg ${theme === "dark"
                ? `bg-primary-color-3-light-${index + 1}`
                : index === 1 ? `bg-primary-color-6` : index === 3 ? "bg-grey-dark-3" : `bg-${color}`}`}
                style={{ minHeight: "2vh" }}>


                <Text cssDiv={`mt-2 text-number-landing-multiple-div col-4`}
                    css={`pos-a text-number-landing-multiple-text`}
                    textLight={`text-white`}>

                    <span>
                        0{index + 1}
                    </span>

                </Text>

                <Text textLight={`text-white`} css={`syst-management-title p-1 t-start col-8 center`}>

                    {data.text}

                </Text>

            </div>

        </CardLayout >

    )
}

export default CardWithNumber