import useLanguage from "../../../Hooks/useLanguage";
import { socialMediaArray } from "../../../JSON/FR/LandingArray";

function SocialMedia() {

    const { languageArray: socialMediaArray } = useLanguage("LandingArray", "socialMediaArray")

    return (

        <div className={`center`}>

            {socialMediaArray?.map(icon => (

                <div key={icon.link}>

                    <a href={icon.link} target='_blank' rel="noreferrer nofollow" >

                        <icon.icon size={35} className={`p-075`} />

                    </a>

                </div>

            ))}
        </div>
    );
}

export default SocialMedia;