import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const i18nInstance = i18n.createInstance();

i18nInstance
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        fallbackLng: "en-GB",
        detection: {
            order: ["localStorage", "navigator"],
        },
        // Add these lines to ensure resources are loaded
        resources: {
            en: {},
            fr: {}
        },
        interpolation: {
            escapeValue: false
        }
    });

export default i18nInstance;