import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import fr from "../../../assets/Logos/fr.svg";
import gb from "../../../assets/Logos/gb.svg";

function LangFlag({ css }) {
    const { i18n } = useTranslation();
    const [flag, setFlag] = useState(i18n.language.startsWith('fr') ? gb : fr);

    useEffect(() => {
        setFlag(i18n.language.startsWith('fr') ? gb : fr);
    }, [i18n.language]);

    const toggleLanguage = () => {
        const newLang = i18n.language.startsWith('fr') ? 'en-GB' : 'fr-FR';
        i18n.changeLanguage(newLang);
    };

    return (
        <div onClick={toggleLanguage} className={`center ${css}`}>
            <img src={flag} style={{ width: "20px" }} alt="language flags stuff" />
        </div>
    );
}

export default LangFlag;