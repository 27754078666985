// React inbuild hooks
import React, { useState, useEffect, useMemo } from "react";

// Personnal hook for global data and stuff
import useGlobalContext from "../../../Hooks/useGlobalContext";

// Layout Component
import CardLayout from "../../Shared/Layout/CardLayout"

// Simple components
import Title from "../../Shared/Basic/Title";
import Text from "../../Shared/Basic/Text";

import { useLocation } from "react-router-dom";

// Principal funcition to render all the landing pages components. 
function LandingCards({
    data,
    index,
}) {

    // Component to render components. All the components on LandingPage are comming frmo LandingArray. Inside they are components to be easier to read here and there. 
    // To render them properlly I need this component in react
    const RenderMappedComponent = () => {
        const memoizedComponent = useMemo(() => {
            return React.createElement(data.EL, { someProps: data.props });
        }, [data.EL, data.props]);

        return memoizedComponent;
    };

    // Get colors depending on theme and position in 
    const [color, setColor] = useState()
    const [textColor, setTextColor] = useState()

    // variable to get the theme
    const { theme } = useGlobalContext()

    const location = useLocation()

    useEffect(() => {
        if (location.hash) {
            const el = document.querySelector(location.hash)
            if (el) {
                const top = window.scrollY + el.getBoundingClientRect().top - 150
                window.scrollTo({ top, behavior: "smooth" })
            }
        }
    }, [location])

    // Function to set colors depending on index. (I should look for a better way one day to do that. Maybe in the LandingArray)
    const pickColor = (theme, index) => {

        const isActuality = index === 0;
        const isRepartition = index === 1;
        const isTestimony = index === 6
        const isImplamentation = index === 7
        const isContact = index === 8

        const color = theme === "dark" ? (index % 2 === 0 ? "primary-color-2" : "primary-bg") :
            (isImplamentation) ? "primary-color-6" :
                (isRepartition || isActuality || isTestimony || isContact) ? "white" :
                    index % 2 === 0 ? "primary-color-6" : "white";

        const textColor = theme === "dark" ? "white" :
            (isImplamentation) ? "white" :
                (isRepartition || isActuality || isTestimony || isContact) ? "primary-color-6" :
                    index % 2 === 0 ? "white" : "primary-color-6";

        setColor(color)
        setTextColor(textColor)

    }

    // UseEffect to get the colors
    useEffect(() => {
        pickColor(theme, index, data)
        // eslint-disable-next-line 
    }, [theme])

    return (

        <>

            <CardLayout
                bg={`bg-${color}`}
                css={`col-12 ml-a mr-a center col ${index === 0 && "mt-3"}`}
                El={`article`}
                p={``}
            >

                <span id={data?.nav} />
                <span id={data.id ? data.id : ""} />


                {data.title ?

                    <div className={`mb-2 center ${data.addTitleCss ? data.addTitleCss : ""}`} >

                        <Title El={`h2`} css={`t-center font-xl text-${textColor} mb-2 mt-3`}>

                            {data.title}

                        </Title>

                    </div>

                    :

                    null

                }

                <CardLayout
                    css={`${(data.intersectring === "expertise" || data.intersectring === "implatation" || data.intersectring === "testimony")
                        ? "col-12 row center"
                        : data.contact
                            ? "col-12 col mb-2 mt-2"
                            : "col-12"}
                      ${data.text ? "mt-2 mb-2" : ""}`}
                    p=''
                    bg=""
                >

                    {data.text ?

                        <Text textLight={`text-${textColor}`}
                            css={`prewrap t-justify font-lg mb-1 col-10`}>

                            {data.text}

                        </Text>

                        :

                        null

                    }

                    {data.textMultiple ?

                        <>

                            <CardLayout bg="" p={`${data?.base && ""}`} css={`display-f fd-r col-12`}>

                                {data?.textMultiple.map((text, i) => (

                                    <Text key={text.text} textLight={`text-${textColor}`} cssDiv={`col-6 ${i === 0 ? "mr-4" : "ml-4"}`} css={`mb-4`}>

                                        {text.text}

                                    </Text>

                                ))
                                }

                            </CardLayout>

                        </>

                        :

                        null

                    }

                    {data?.EL ?

                        <RenderMappedComponent />

                        :

                        null
                    }


                </CardLayout >


            </CardLayout >
        </>
    );
}

export default LandingCards;