import LandingNav from "../LandingPage/LandingElements/LandingNav";

function RGPD() {

    return (

        <>
            <LandingNav type={`not-principal`} title={`Notre politique RGPD `} colorTitle={`text-white`} />

        </>

    );
}

export default RGPD;