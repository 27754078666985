import React from "react";

const MakeBold = ({ text, terms }) => {

    const regex = new RegExp(`\\b(${terms.join('|')})\\b`, 'gi');
    const parts = text.split(regex);

    return (
        <>
            {parts
                .map((part, index) =>
                    terms.includes(part.toLowerCase())

                        ? (
                            <span key={index} className="fw-b">
                                {part}
                            </span>

                        ) : (
                            <React.Fragment key={index}>{part}</React.Fragment>
                        ),
                )}
        </>
    );
};

export default MakeBold