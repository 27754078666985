// react component
import React, { useEffect, useState } from "react";

// Layout component
import CardLayout from "../Shared/Layout/CardLayout";

// React Router Dom 6 hook to get the pathname
import { useLocation } from "react-router-dom";

// This array is for all the displays of the group page and also for the job pages. 
import useLanguage from "../../Hooks/useLanguage";
// import { singlePageArray } from "../../JSON/FR/SinglePageArray";

// used to show the topbar
import LandingNav from "../LandingPage/LandingElements/LandingNav";

// BIG and complexe component
import Multiple from "../Shared/Layout/Multiple";

// Simple component
import Title from "../Shared/Basic/Title";

function Syst() {

    // First we get the name of the path (ex groupe-page for the groupe page)
    const { pathname } = useLocation()

    // Language hook to get all whats on singlepage x)
    const { languageArray: singlePageArray } = useLanguage("SinglePageArray", "singlePageArray")

    // Then he filters in the singlePageArray to get ALL the infos to make the display according to to the pathname

    const [getPageType, setGetPageType] = useState()

    // filter the page type trought the singlepagearray

    useEffect(() => {

        if (singlePageArray) {

            const typage = singlePageArray.filter(info => info.pathname.includes("syst"))
            setGetPageType(typage)

        }

    }, [singlePageArray, pathname])

    // Make the title page + icons once you get the type

    useEffect(() => {

        if (getPageType && getPageType.length > 0) {

            const [{ title, pagelogo }] = getPageType;

            document.title = `Groupe Studiel - ${title}`;

            if (!title.includes("Studiel")) {
                let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = pagelogo;
                document.getElementsByTagName('head')[0].appendChild(link);
            }

        }

    }, [getPageType])


    // JSX 

    return (

        <>

            <CardLayout css={``} p='' style={{ minHeight: "80vh" }}>

                {getPageType
                    ?.map((info, index) => (

                        <React.Fragment key={index}>

                            <CardLayout css={`center fd-c`} p="">

                                <LandingNav
                                    colorTitle={`text-white`}
                                    type={`not-principal`}
                                    title={info.title}
                                    bgLightMode={`bg-${info.color}`}
                                    logo={info?.logo}
                                    addTitle={info?.dontAddTitle} />

                            </CardLayout>

                            {info.subTile ?

                                <Title El={`h2`} css={`center m-2`}>{info.subTile}</Title>

                                :

                                null

                            }

                            {info.multiple ?

                                <Multiple datas={info?.multiple}
                                    color={info?.color}
                                    col={info?.col}
                                    reduce={info?.reduce} />

                                :

                                null

                            }

                        </React.Fragment>
                    ))}

            </CardLayout>


        </>
    );
}

export default Syst;