import { useState } from "react";

function useSubmit() {

    const [pending, setPending] = useState(null)
    const [error, setError] = useState(null)
    const [resMsg, setResMsg] = useState(null)

    // we'll send data as default set. But if we put it in the arguments it will send dataSent
    const handleSubmit = async (
        {
            e = "",
            url,
            option = "POST",
            body,
            headers = {
                'Content-Type': 'application/json'
            },
        }
    ) => {

        // console.log("BODY USESUBMIT", body)
        // console.log("URL USESUBMIT", url)
        // console.log("RESMSG USESUBMIT", resMsg)
        // console.log("ERROR USESUMIBT", error)

        e && e.preventDefault()

        setPending(true)
        setError(null)

        try {
            const res = await fetch(url,
                {
                    method: option,
                    headers: headers,
                    body: JSON.stringify(body)
                }
            )

            if (!res.ok) {
                throw new Error('throw res', res.statusText)
            }

            setResMsg(res)


            setPending(false)
            setError(null)

        }

        catch (err) {
            // console.log(err)
            setError(err.message)
            setPending(false)
        }

    }

    return { handleSubmit, pending, error, resMsg }

}

export default useSubmit;