import useTheme from "./useTheme";

function useAll() {

  ////////////////
  // Personal Hook For the theme setter
  ////////////////

  const { theme, setTheme } = useTheme();

  const all = {

    // themes settings
    theme,
    setTheme
  };

  return { all };
}

export default useAll;
