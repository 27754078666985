import Title from "../../Shared/Basic/Title";
import TextGroup from "../../Shared/Basic/TextGroup";

function Lead({
    title,
    array
}) {

    return (

        <div className={`bg-grey-dark-2 col-12 center p-4 col`}>

            <Title css={`text-white mb-2 pb-3 font-title-group fw-b prewrap`}
                El={`h2`}>

                {title}

            </Title>

            <div className={`row col-12 j-space-around`}>

                {array?.map((lead, index) => (

                    <div key={index} className={`col-4-xxl col-4-xl col-4-lg col-12-md col-12-sm col-12-xs center col `}>

                        <div className={`lead-bg`}>

                            <img src={lead.pic}
                                alt={`Studiel CEOS COO`}
                                className={`circle-image-lead`} />

                        </div>

                        <TextGroup css={`text-white prewrap`}
                            textAlign={`t-center`}>

                            {lead.text}

                        </TextGroup>

                    </div>

                ))}

            </div>

        </div>
    );
}

export default Lead;