// Landing Page

import React from "react";
// Different components for the principal page
// First carrousel
import LandingVideo from "./LandingElements/LandingVideo";

// Acuality is here 
import LandingActualityOnCarrousel from "./LandingElements/LandingActualityOnCarrousel";

// Navigation on carrousel (obviously)
import LandingNav from "./LandingElements/LandingNav";

// Mapped elements
import LandingCards from "./LandingElements/LandingCards";

// Array with ALL the info to render the landing page. Edit this to change the content
// import { cardsOnCarrousel } from "../../JSON/FR/LandingArray";

// Studiel Logo 
import { studielinwhite } from "../../generalimports";
import useLanguage from "../../Hooks/useLanguage";

function Landing() {

    const { languageArray: cardsOnCarrousel } = useLanguage('LandingArray', "cardsOnCarrousel")

    const MemoizedLandingCards = React.memo(LandingCards);

    // JSX

    return (
        <>

            <div className={`pos-r`}>

                <LandingVideo />

                <LandingNav logo={studielinwhite} />

                <LandingActualityOnCarrousel />

            </div>

            {cardsOnCarrousel?.map((data, i) => (
                <MemoizedLandingCards key={i} data={data} index={i} />
            ))}

        </>

    );
}

export default Landing;