import React from "react";
import useGlobalContext from "../../../Hooks/useGlobalContext"

const Title = React.forwardRef(({
    title,
    css,
    children,
    El = "h1",
    textLight = "text-black"
}, ref) => {

    const { theme } = useGlobalContext()

    return (

        <El className={`fw-b tt-u dodis-google-font ${css} ${theme === "dark" ? "text-white" : textLight}`}
            ref={ref}
        >

            {children}

            {title}

        </El>

    );
})

export default Title;