import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import { GlobalContextProvider } from './Context/GlobalContext';
import { I18nextProvider } from 'react-i18next';
import i18nInstance from './Functions/i18n.js';

// Styles
import './css/App.css';
import './css/index.css'
import "./css/composant/carrousel.css"

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <I18nextProvider i18n={i18nInstance}>
      <GlobalContextProvider>
        <App />
      </GlobalContextProvider>
    </I18nextProvider>
  </React.StrictMode>,
)