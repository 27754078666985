import React, { useRef, useEffect, useState } from "react";

import Title from "../../Shared/Basic/Title";

import TextGroup from "../../Shared/Basic/TextGroup";
import Btn from "../../Shared/Basic/Btn";

import { Interweave } from "interweave";

function TextAndVid({ text }) {

    const {
        bg,
        textWithVidRightPresence,
        subtitleh41,
        textColor,
        navlink,
        video,
        picVid,
        informations,
        orangeCard = false,
        altPicVid,
        picVidsArray,
        fallback,
    } = text

    const cols = "col-6-xxl col-6-xl col-12-lg col-12-md col-12-sm col-12-xs"

    const FadingPicVidGallery = ({ picVids, interval = 2000 }) => {

        const [currentIndex, setCurrentIndex] = useState(0);
        const [previousIndex, setPreviousIndex] = useState(picVids.length - 1);

        useEffect(() => {
            const timer = setInterval(() => {
                setTimeout(() => {
                    setPreviousIndex(currentIndex);
                    setCurrentIndex((prevIndex) => (prevIndex + 1) % picVids.length);
                }, 1000); // Duration of the cross-fade effect
            }, interval);
            return () => clearInterval(timer);
        }, [picVids, interval]);

        return (

            <div className={`${cols} p-3 center`}>

                <div className={`pos-r center mb-3 mt-3 height-fading-pics`} style={{ width: "100%" }}>

                    {picVids.map((picVid, index) => (

                        <img
                            key={index}
                            src={picVid}
                            className={`bg-grey-for-oil rounded-video-image-corner pos-a z-2 fade-transition 
                                ${index === currentIndex ? 'fade-in' : ''} 
                                ${index === previousIndex ? 'fade-out' : ''}`}
                            alt={`PicVid ${index + 1}`}
                            style={{
                                boxSizing: "border-box",
                                opacity: index === currentIndex || index === previousIndex ? 1 : 0,
                                transition: 'opacity 1s ease-in-out',
                                height: "100%",
                                width: "90%",
                                maxWidth: "700px",
                                objectFit: "cover"
                            }}
                        />
                    ))}

                </div>

            </div >
        );
    };

    const ImageComponent = ({ picVid, altPicVid, orangeCard }) => {

        const [isHovered, setIsHovered] = useState(false);

        if (!picVid) return null;

        return (

            <div className={`${cols} p-3 center`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}>

                <div className={`pos-r center`}>

                    <img
                        src={picVid}
                        className={`rounded-video-image-corner z-2 ${isHovered ? 'fade-out-orange-pic' : ''}`}
                        alt="text for these stuffy stuff studiel"
                        style={{
                            display: isHovered ? 'none' : 'block',
                            objectFit: "cover",
                            height: "100%",
                            width: "90%"
                        }}
                    />

                    {altPicVid && (

                        <img
                            src={altPicVid}
                            className={`rounded-video-image-corner pos-r z-2 alt-pic-orange ${isHovered ? 'fade-in-orange-pic' : ''}`}
                            alt="Alternative image"
                            style={{
                                display: isHovered ? 'block' : 'none',
                                objectFit: "cover",
                                height: "100%",
                                width: "90%"
                            }}
                        />

                    )}

                    {orangeCard && !isHovered && (

                        <div className={`orange-card center rounded-video-image-corner`} />

                    )}

                </div>

            </div>
        );
    }


    const VideoComponent = ({ video }) => {

        const videoRef = useRef(null);

        useEffect(() => {
            if (video) {
                const videoElement = videoRef.current;
                videoElement.oncanplaythrough = () => {
                    videoElement.play();
                };
            }
        }, [video]);

        return (

            <div className={`${cols} p-3 center`}>

                <video ref={videoRef}
                    className={`card-video-reals rounded-video-image-corner`}
                    poster={fallback}
                    muted
                    loop>

                    <source src={video} autoPlay muted loop type="video/mp4" />

                </video>

            </div>
        )
    }

    return (

        <div className={`col-12 row j-space-evenly ai-center mt-3 pl-2 pr-2
            ${bg ? bg : ""} 
            ${textWithVidRightPresence ? "fd-rev" : ""}`}>

            <Title El={`h3`}
                css={`font-title-group-sub display-f col-12 p-2 
                ${textColor ? textColor : ""} 
                ${textWithVidRightPresence ? "j-flex-end" : "j-flex-start"}`}>

                {subtitleh41}

            </Title>

            <div className={`${cols}`}>

                {Array.isArray(informations)

                    && informations?.map((info, index) => (

                        <React.Fragment key={index}>

                            <div>

                                <TextGroup css={`t-justify prewrap p-2 col-12 
                                    ${textColor ? textColor : ""}`}>

                                    <span>

                                        <span className={`${info.susubCss ? info.susubCss : ""} display-b`}>

                                            {info.subSubTitle41}

                                        </span>

                                        {info.textWithVidLeft &&

                                            <span>
                                                <Interweave content={info.textWithVidLeft} />
                                            </span>

                                        }


                                        {info.textWithVidRight &&

                                            <span>
                                                <Interweave content={info.textWithVidRight} />
                                            </span>

                                        }

                                    </span>

                                    <br />

                                    {navlink ?

                                        <span className={`fw-b pointer tdec-u`}
                                            onClick={() => window.open(`${navlink}`)}>En savoir plus ...</span>
                                        :

                                        null
                                    }

                                </TextGroup>

                                {!Array.isArray(info.list) ? null :

                                    <div className={`display-f col p-2  ${info.textColor ? info.textColor : ""}`}>

                                        {info.list.map(l => (

                                            <div key={l}
                                                className={`font-textgroup display-f ai-flex-start col-6`}>

                                                -  {l}

                                            </div>

                                        ))}

                                    </div>

                                }

                            </div>

                            {!info.textButtonTextVid ? null :

                                <Btn css={info.cssButtonTextVid}
                                    btnStyleLight={info.cssButonLightStyle}
                                    handleClick={() => window.open(info.navButtonTextVid)}>

                                    {info.textButtonTextVid}

                                </Btn>
                            }

                        </React.Fragment>

                    ))}

            </div>

            {!Array.isArray(picVidsArray) ? null :

                <FadingPicVidGallery
                    picVids={picVidsArray}
                    width="800px"
                    height="450px" />

            }


            {!picVid ? null :

                <ImageComponent
                    picVid={picVid}
                    altPicVid={altPicVid}
                    orangeCard={orangeCard} />

            }


            {!video ? null :

                <VideoComponent video={video} />

            }

        </div>

    );
}

export default TextAndVid;