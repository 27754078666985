// REACT =)
import React from "react";

// Simple Components
import TextGroup from "../../Shared/Basic/TextGroup";

function ValueCards({ text }) {

    // JSX

    return (

        <div className={`col-12 row`}>

            {text.map((info, i) => (

                <React.Fragment key={i}>

                    <div className={`center col col-4-xxl col-4-xl col-12-lg col-12-md col-12-sm col-12-xs pb-2 pt-2`}>

                        {info.firstText ?

                            <div className={`center pb-2 pt-2`}>

                                <TextGroup css={`pt-2 pb-2 values-cards-padding-margin prewrap t-justify`}>

                                    {info.firstText}

                                </TextGroup>

                            </div>
                            :

                            null

                        }


                        {info.icon ?

                            <img src={info?.icon}
                                style={{ width: "12vw" }}
                                className={`text-primary-color-5`}
                                alt={`Studiel Logos Values "Nos Valeurs"`} />

                            :

                            null

                        }



                        {info.secondText ?

                            <div className={`center pb-2 pt-2`}>

                                <TextGroup css={`pt-2 pb-2 values-cards-padding-margin prewrap t-justify`}>

                                    {info.secondText}

                                </TextGroup>

                            </div>

                            :

                            null

                        }

                        {info.firstImg ?

                            <div className={`img-values-container`}>

                                <img src={info.firstImg}
                                    className={`img-values`}
                                    alt={`Studiel Values ${info?.alt}`}
                                />

                                <img src={info.secondImg}
                                    className={`img-values-top`}
                                    alt={`Studiel Values ${info?.alt}`}
                                />

                            </div>

                            :

                            null


                        }

                    </div>


                </React.Fragment>

            ))}


        </div>

    );
}

export default ValueCards;