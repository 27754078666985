// React inbuild hooks
import React, { useEffect, useState } from "react";

// Personal Hook to switch language
import useLanguage from "../../../Hooks/useLanguage"

// import { navOnCarrousel } from "../../../JSON/FR/LandingArray";
// import { generalTranslate } from "../../../JSON/FR/GeneralArray";
// import { navOnCarrouselTablette } from "../../../JSON/FR/LandingArray";
// import { navOnCarrouselBurger } from "../../../JSON/FR/LandingArray";

// Special package to scroll-smouth to an anchor in react
import { HashLink } from 'react-router-hash-link';

// Personal Hook for general data
import useGlobalContext from "../../../Hooks/useGlobalContext"

// Simple Component
import Title from "../../Shared/Basic/Title";
import Btn from "../../Shared/Basic/Btn";

// HTML parser (for text that comes from a json and has html in it)
import { Interweave } from "interweave";

// Component for the flag
import LangFlag from "../../Shared/All/LangFlag";

import {
    // studiellogonly, 
    bugerMenu
} from "../../../generalimports"

// Component that wraps a all the links (before they turn orange)
const LinkWrapper = ({
    children,
    bg,
    handleClick,
    tab,
    rightMenu,
    rightBellowModal,
    index,
    css
}) => {

    const [modal, setModal] = useState(false)
    const [activeIndex, setActiveIndex] = useState(null)

    return (

        <>

            <li className={`p-2 bg-hover-primary-color-4  ${css} ${!bg ? "bg-transparent-dark-2" : "bg-primary-color-6"} trans-all-05 `}
                onClick={handleClick}
                onMouseEnter={() => setActiveIndex(index)}
                onMouseLeave={() => setActiveIndex(null)}

            >

                <p className={`font-ml fw-500 lp050 p-1 ${index === activeIndex ? "text-black" : "text-white"} fw-b trans-all-05`}
                    style={{ minHeight: "30px" }}
                    onMouseEnter={() => {
                        if (tab) {
                            setModal(c => !c)
                            setActiveIndex(null)
                        }
                    }}
                >

                    {children}


                    <span className={`pos-r display-b`} style={{ height: "100%", width: "100%" }}>

                        {tab && modal &&

                            <span className={`nav-modale display-b`}
                                onClick={() => setModal(c => !c)}>

                                <span className={`nav-modale-leave`}
                                    style={{ right: rightBellowModal }}
                                    onMouseLeave={() => setModal(c => !c)} />

                                <span className={`nav-modal-menu p-025 display-b`}
                                    //to set the modal placement, passed in props
                                    style={{ right: rightMenu }}
                                >

                                    {tab
                                        ?.map((t, index) => (

                                            <React.Fragment key={index}>

                                                {t.nav ?

                                                    <HashLink to={`#${t.nav}`}>


                                                        <LinkWrapper bg={bg}>

                                                            <Interweave content={t?.title} />


                                                        </LinkWrapper>

                                                    </HashLink>

                                                    :

                                                    <LinkWrapper bg={bg}>

                                                        <a href={`${t.navto}`}
                                                            className={`${t.cssA}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer">

                                                            <span className={t.css}>

                                                                <Interweave content={t?.title} />

                                                            </span>

                                                        </a>

                                                    </LinkWrapper>

                                                }

                                            </React.Fragment>

                                        ))}

                                </span>

                            </span>
                        }

                    </span>

                </p>


            </li>


        </>
    )
}

// Navigation on the carrousel 
function LandingNav({
    type,
    titlePlacement,
    title,
    bgLightMode = "bg-primary-color-6",
    bgDarkMode = "bg-primary-color-2",
    colorTitle,
    logo,
    handleApply,
    addTitle,
}) {

    // To swith languages
    const { languageArray: navOnCarrousel } = useLanguage("LandingArray", "navOnCarrousel")
    const { languageArray: generalTranslate } = useLanguage("GeneralArray", "generalTranslate")
    const { languageArray: navOnCarrouselTablette } = useLanguage("LandingArray", "navOnCarrouselTablette")
    const { languageArray: navOnCarrouselBurger } = useLanguage("LandingArray", "navOnCarrouselBurger")

    // To get theme
    const { theme } = useGlobalContext()

    // Open and close the burger menu
    const [burgerOpen, setBurgerOpen] = useState(false)

    // To select BG depending on position
    const [bg, setBG] = useState(false)

    // To select BG depending on position
    // If the screenWitdth is below 1350, is remains false. (so when the burger is there, it doesnt transform to orange)
    const handleScroll = () => {

        const position = window.pageYOffset;
        const screenWidth = window.innerWidth;

        if (screenWidth > 800) {
            setBG(position > 2 ? true : false);
        } else {
            setBG(false);
        }

    }

    // To select BG depending on position
    useEffect(() => {

        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
        // eslint-disable-next-line
    }, [])

    // JSX

    return (

        <nav className={`
        ${type === "not-principal"
                ? "pos-r"
                : "pos-f pos-f-nav-studiel"} 
        ${type !== "not-principal" && !bg
                ? "bg-realtrans"
                : theme === "dark"
                    ? `${bgDarkMode}`
                    : `${bgLightMode} bbs`} trans-all-05 display-f j-space-between col-12 z-100`}>

            <div id={`#`} className={`center`}>

                {!logo ?

                    null

                    :

                    <HashLink to={"/#"} className={`center pos-r ${type !== "not-principal" ? "" : "display-logo pos-a"}`}>

                        <div className={`p-025 ml-1 center ${bg ? "" : ""}
                         ${type !== "not-principal" ? "nav-display-logo" : ""}`}>

                            <img className={`center m-a`}
                                src={logo}
                                style={{ maxWidth: `${(title?.includes('Group') || type !== "not-principal") ? "220px" : "fit-content"}`, height: `${type !== "not-principal" ? "fit-content" : "55px"}` }}
                                alt={`Studiel Logo Intelligence Industrielle`} />

                            {!addTitle && title &&

                                <Title css={`pl-1 pr-1 text-white`} El={`h3`}>{title}</Title>

                            }

                        </div>

                    </HashLink>
                }

            </div>

            <div className={`${type !== "not-principal" ? 'nav-display p-125' : titlePlacement === "offer" ? "col-12 nav-display-offer" : "col-12 nav-display-not-offer"}`} >

                {type !== "not-principal" ?

                    <ul className={`display-f j-flex-end`}>

                        <>
                            {navOnCarrousel
                                ?.map((element, i) => (

                                    <React.Fragment key={i}>

                                        {element.nav ?

                                            <HashLink key={element.title}
                                                to={`#${element.nav}`}>


                                                <LinkWrapper bg={bg}>

                                                    <Interweave content={element.title} />

                                                </LinkWrapper>

                                            </HashLink>

                                            :

                                            element.tab ?

                                                <LinkWrapper bg={bg}
                                                    tab={element?.tab}
                                                    rightMenu={"600px"}
                                                    rightBellowModal={"500px"}
                                                    index={i}>

                                                    <Interweave content={element.title} />

                                                </LinkWrapper>

                                                :

                                                <LinkWrapper bg={bg}>

                                                    <a href={`${element.navto}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer">

                                                        <span className={element.css}>

                                                            <Interweave content={element.title} />

                                                        </span>

                                                    </a>

                                                </LinkWrapper>

                                        }

                                    </React.Fragment>

                                ))}


                        </>

                    </ul>

                    :

                    <>

                        <div>

                            {title &&

                                <Title css={`prewrap ${titlePlacement ? 'font-ml ml-2' : 'font-xl p-125'} ${colorTitle}`}>

                                    <Interweave content={title} />

                                </Title>

                            }

                        </div>


                        {titlePlacement ?

                            <Btn
                                handleClick={handleApply}
                                cssDiv={`center mr-3`}
                                btnStyleLight={`btn-outlined-primary-color-4 text-primary-color-4 text-hover-white font-lg`}>

                                {generalTranslate?.apply}

                            </Btn>

                            :

                            null

                        }

                    </>

                }

                {/* !bg ? "bg-transparent-dark-2" : "bg-primary-color-4" */}
                {/* ${type !== "not-principal" ? '' : ""} */}

                {titlePlacement ? null :
                    <LangFlag
                        css={`p-2
                    ${!bg ? (type !== "not-principal" ? 'bg-transparent-dark-2' : 'lang-pos') : (type !== "not-principal" ? 'bg-primary-color-6 ' : 'lang-pos')} bg-tr bg-hover-primary-color-4  trans-all-05 `} />
                }

            </div>

            <div className={`${type !== "not-principal" ? 'col-10 nav-tablette p-125' : titlePlacement === "offer" ? "nav-tablette-offer col-12 j-center" : "nav-tablette-not-offer col-12 "}`} >

                {type !== "not-principal" ?

                    <ul className={`display-f j-flex-end`}>

                        <>
                            {navOnCarrouselTablette
                                ?.map((element, i) => (

                                    <React.Fragment key={i}>

                                        {element.navto ?

                                            <LinkWrapper bg={bg}>

                                                <a href={`${element.navto}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className={`pos-r`}>

                                                    <span className={element.css}>

                                                        <Interweave content={element.title} />

                                                    </span>

                                                </a>

                                            </LinkWrapper>

                                            :

                                            <LinkWrapper bg={bg}
                                                tab={element?.tab}
                                                rightMenu={"50px"}
                                                rightBellowModal={"0px"}
                                                index={i}>

                                                <Interweave content={element.title} />

                                            </LinkWrapper>

                                        }

                                    </React.Fragment>

                                ))}

                        </>
                    </ul>

                    :

                    <>
                        <div>

                            {title &&

                                <Title css={`font-xl p-125 prewrap p-1 ${colorTitle}`}>

                                    <Interweave content={title} />

                                </Title>

                            }

                        </div>


                        {titlePlacement ?

                            <Btn
                                handleClick={handleApply}
                                cssDiv={`center`}
                                btnStyleLight={`btn-outlined-primary-color-4 text-primary-color-4 text-hover-white font-lg`}>

                                {generalTranslate?.apply}

                            </Btn>

                            :

                            null

                        }

                    </>

                }

                {titlePlacement ? null :
                    <LangFlag
                        css={`p-2
                    ${!bg ? (type !== "not-principal" ? 'bg-transparent-dark-2' : 'lang-pos') : (type !== "not-principal" ? 'bg-primary-color-4' : 'lang-pos')} bg-tr bg-hover-primary-color-4  trans-all-05 `} />
                }

            </div>

            {type !== "not-principal" ?

                <nav className={`nav-burger `}>

                    <div className={`pos-f display-f bg-transparentDark-dark-4 br-lg p-075`}
                        style={{ width: "fit-content", top: "10px", left: "10px" }}>

                        <img src={bugerMenu}
                            alt={`logo studiel burger menu navigation`}
                            style={{ width: "40px" }}
                            onClick={() => setBurgerOpen(c => !c)} />

                        <div className={`center`}>

                            {!burgerOpen &&

                                <img className={`center pl-2`}
                                    src={logo}
                                    style={{ maxWidth: "120px" }}
                                    alt={`Studiel Logo Intelligence Industrielle`} />

                            }

                        </div>

                    </div>

                    <div className={`${burgerOpen ? "burger-modal" : ""}`}
                        onClick={() => setBurgerOpen(c => !c)}
                    >

                        {burgerOpen &&

                            <ul className={`nav-menu-burger`}
                                onClick={(e) => e.stopPropagation()}>

                                {navOnCarrouselBurger
                                    ?.map((element, i) => (

                                        <React.Fragment key={i}>

                                            {element.nav ?

                                                <HashLink key={element.title}
                                                    to={`#${element.nav}`}>


                                                    <LinkWrapper bg={bg}
                                                        type={'burger'}
                                                        css={`z-800`}
                                                        handleClick={() => setBurgerOpen(false)}>

                                                        <Interweave content={element.title} />


                                                    </LinkWrapper>

                                                </HashLink>

                                                :

                                                <LinkWrapper bg={bg}
                                                    type={'burger'}
                                                    handleClick={() => setBurgerOpen(false)}>

                                                    <a href={`${element.navto}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer">

                                                        <span className={element.css}>

                                                            <Interweave content={element.title} />

                                                        </span>

                                                    </a>

                                                </LinkWrapper>

                                            }

                                        </React.Fragment>

                                    ))}

                                <LangFlag
                                    css={`p-2  ${!bg ? (type !== "not-principal" ? 'bg-transparent-dark-2' : 'lang-pos') : (type !== "not-principal" ? 'bg-primary-color-4' : 'lang-pos')} bg-tr bg-hover-primary-color-4  trans-all-05 `} />

                            </ul>

                        }

                    </div>

                </nav>

                :

                null

            }

        </nav >
    );
}

export default LandingNav;